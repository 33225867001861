import React from 'react';
import { useImmer } from 'use-immer';
import { css } from '@emotion/core';
import _ from 'lodash';
import { Button, Container, Card, CardBody, CardTitle, Row, Col, FormGroup } from 'shards-react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useMediaQuery } from 'react-responsive';
import ReactShadowScroll from 'react-shadow-scroll';

import images from '../../images/images';
import useRouter from '../../hooks/use-router';
import MainFooter from '../../components/layout/MainFooter';

const TopScrollContainer = ({
  breadcrumb,
  titleBackground,
  background,
  title,
  titleTab,
  isShowBtnMobile = false,
  isShowLineMobile = false,
  titleBtn,
  handleChange,
  children,
  customPadding = false,
  ...props
}) => {
  const [state, produce] = useImmer({
    ...location.state,
    tabSelect: 0,
    btnSelect: null,
  });
  const { tabSelect, btnSelect } = state;
  const { push, pathname } = useRouter();
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 402px)' });

  const CustomBreadcrumb = (props) => (
    <Breadcrumb style={{ position: 'absolute', top: '-40px' }}>
      {_.map(props.datas, (item, index) => (
        <Breadcrumb.Item key={index} href={item?.to} active={item?.to ? false : true}>
          {item?.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );

  return (
    <Container
      fluid
      css={styles}
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: '#F1F1F1',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'absolute',
        top: 0,
        height: '100vh',
        padding: 0,
      }}
    >
      <Row noGutters className="h-100 w-100">
        <Card
          className="mx-auto card"
          style={{
            width: `${isMobile ? 'calc(100% - 20px)' : 'calc(100% - 300px)'}`,
            maxWidth: 1140,
            height: `${isSmallMobile ? 'calc(100vh - 140px)' : isMobile ? 'calc(100vh - 215px)' : 'calc(100vh - 178px)'}`,
            marginTop: isSmallMobile ? 100 : 131,
            position: 'relative',
            borderRadius: 6,
            backgroundColor: 'rgba(247,244,238,1)',
          }}
        >
          <CustomBreadcrumb datas={breadcrumb} />
          <CardTitle
            className="mb-0"
            style={{
              minHeight: isSmallMobile ? 30 : 50,
              backgroundImage: `url(${titleBackground})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
            }}
          ></CardTitle>
          <CardBody className={customPadding ? "customCardBody" : "cardbody"} 
          style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', paddingTop: isSmallMobile && 0}}>
            <FormGroup className="text-center mx-auto" style={{ width: '100%' }}>
              {!isSmallMobile && (<h2>{title}</h2>)}
              {titleTab && (
                <Row className={!isMobile && titleBtn ? 'mt-4 mb-4 mx-auto nav' : 'mt-2 mx-auto nav'}>
                  {_.map(titleTab, (item, index) => (
                    <Col
                      key={index}
                      className="px-0"
                      onClick={() => {
                        handleChange(index);
                        produce((draft) => void (draft.tabSelect = index));
                      }}
                      style={{ cursor: 'pointer', color: tabSelect == index ? '#5E5E5E' : '#C5C1BA' }}
                    >
                      {item}
                      <div className="mt-2" style={{ height: 3, backgroundColor: tabSelect == index ? '#52B54F' : '#C5C1BA' }} />
                    </Col>
                  ))}
                </Row>
              )}
              {(!isMobile || isShowBtnMobile) && titleBtn && (
                <Row
                  className={isMobile ? 'mt-4 mx-auto titleBtn' : 'mt-4 mb-4 mx-auto titleBtn'}
                  style={{ justifyContent: isMobile ? 'left' : 'center' }}
                >
                  {_.map(titleBtn, (item, index) => (
                    <a
                      key={index}
                      className="px-3 mx-2"
                      href={`#item${index}`}
                      onClick={() => produce((draft) => void (draft.btnSelect = index))}
                      style={{
                        border: btnSelect == index ? '1px solid rgba(82, 181, 79, 1)' : '',
                        color: btnSelect == index ? 'rgba(82, 181, 79, 1)' : '',
                        marginBottom: isMobile ? 15 : '',
                        fontSize: 16,
                      }}
                    >
                      {item}
                    </a>
                  ))}
                </Row>
              )}
              {(!isMobile || isShowLineMobile) && titleBtn && (
                <Row style={{ background: '#C5C1BA', height: 1, marginRight: 0, marginLeft: 0 }} />
              )}
            </FormGroup>
            <ReactShadowScroll
              isShadow={false}
              scrollColor="#52B54F"
              style={{
                // height: 'calc(vh)',
                // marginBottom: 20,
                // marginTop: 20,
                padding: 0,
                marginLeft: 0,
                marginRight: 0,
                maxWidth: '100%',
              }}
              styleSubcontainer={{
                overflowX: 'hidden',
                scrollBehavior: 'smooth',
              }}
            >
              <div>{children}</div>
            </ReactShadowScroll>
          </CardBody>
        </Card>
      </Row>
      {!isSmallMobile && (<MainFooter copyright="版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰竹教產後護理之家 | 國泰台南產後護理之家 Copyright © Cathay Healthcare Inc., Ltd. All Rights Reserved." />)}
    </Container>
  );
};

export default TopScrollContainer;

const styles = css`
  h2 {
    color: rgba(94, 94, 94, 1);
    font-size: 36px;
    font-weight: 500;
  }
  h4 {
    color: rgba(94, 94, 94, 1);
    font-size: 24px;
    font-weight: 500;
  }
  h5 {
    color: rgba(94, 94, 94, 1);
    font-size: 24px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  h6 {
    color: rgba(94, 94, 94, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .card {
    width: 70%;
    min-width: 315px;
    @media (max-width: 992px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      width: 80%;
    }
  }
  .cardbody {
    padding-left: 100px;
    padding-right: 100px;
    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .customCardBody {
    padding-left: 50px;
    padding-right: 50px;
    @media (max-width: 1120px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: 992px) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .title {
    margin-left: 20px;
    margin-bottom: 20px;
    @media (max-width: 1292px) {
      margin: 0;
      justify-content: center;
    }
  }
  .nav {
    width: 30%;
    @media (max-width: 1292px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .btn {
    width: 123px;
    max-height: 42px;
    font-size: 16px;
  }
  .data {
    padding-left: 20px;
    width: 50%;
    @media (max-width: 1292px) {
      padding: 0px;
      width: 100%;
      text-align: center;
    }
  }
  .data div {
    @media (max-width: 1292px) {
      margin: 0 auto;
    }
  }
  .breadcrumb {
    background-color: transparent;
  }
  .breadcrumb-item {
    color: white;
    font-size: 14px;
    font-weight: 400;
  }
  .breadcrumb-item a {
    color: white;
  }
  .breadcrumb-item.active {
    user-select: none;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    margin-top: 8px;
    border: 3px solid transparent;
    border-right: 0;
    border-left: 5px solid white;
  }
  .titleBtn a {
    cursor: pointer;
    border: 1px solid rgba(94, 94, 94, 1);
    height: 32px;
    border-radius: 21px;
    padding-top: 3px;
    color: rgba(94, 94, 94, 1);
  }
  .titleBtn a:hover {
    color: rgba(82, 181, 79, 1);
    border: 1px solid rgba(82, 181, 79, 1);
  }
  .fiveCharOddItems{
    padding-left: 20px;
    @media (max-width: 992px) {
      padding-left: 120px;
    }
    @media (max-width: 767px) {
      padding-left: 90px;
    }
    @media (max-width: 645px) {
      padding-left: 45px;
    }
  }
  .fiveCharEvenItems{
    padding-left: 20px;
    @media (max-width: 992px) {
      padding-left: 90px;
    }
    @media (max-width: 767px) {
      padding-left: 75px;
    }
    @media (max-width: 645px) {
      padding-left: 20px;
    }
  }
`;
