import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { DefaultLayout, BlankLayout } from './layouts';

// Route Views
import Home from './views/Home/Home';
import News from './views/News/News';
import MomBabyInfos from './views/MomBaby/MomBabyInfos';
import MomClassrooms from './views/MomBaby/MomClassrooms';
import WarmRecords from './views/MomBaby/WarmRecords';
import MedicalLineup from './views/Care/MedicalLineup';
import Diet from './views/Care/Diet';
import Mother from './views/Care/Mother';
import Baby from './views/Care/Baby';
import AboutPostpartum from './views/About/AboutPostpartum';
import TaipeiHall from './views/GuildHall/TaipeiHall';
import NeihuHall from './views/GuildHall/NeihuHall';
import HsinchuHall from './views/GuildHall/HsinchuHall';
import TainanHall from './views/GuildHall/TainanHall';
import Book from './views/Book/Book';
// const BlankIconSidebarLayout = ({ children }) => (
//   <IconSidebar noNavbar noFooter>
//     {children}
//   </IconSidebar>
// );

export default [
  {
    path: '/:id(\\d*)',
    exact: true,
    layout: DefaultLayout,
    component: Home,
  },
  {
    path: '/news/:id?',
    layout: DefaultLayout,
    component: News,
  },
  {
    path: '/mom-baby-infos/:id?',
    layout: DefaultLayout,
    component: MomBabyInfos,
  },
  {
    path: '/mom-classrooms/:id?',
    layout: DefaultLayout,
    component: MomClassrooms,
  },
  {
    path: '/warm-records/:id?',
    layout: DefaultLayout,
    component: WarmRecords,
  },
  {
    path: '/medical-lineup/:id?',
    layout: DefaultLayout,
    component: MedicalLineup,
  },
  {
    path: '/diet',
    layout: DefaultLayout,
    component: Diet,
  },
  {
    path: '/mother',
    layout: DefaultLayout,
    component: Mother,
  },
  {
    path: '/baby',
    layout: DefaultLayout,
    component: Baby,
  },
  {
    path: '/about-postpartum',
    layout: DefaultLayout,
    component: AboutPostpartum,
  },
  {
    path: '/taipei-hall',
    layout: DefaultLayout,
    component: TaipeiHall,
  },
  {
    path: '/neihu-hall',
    layout: DefaultLayout,
    component: NeihuHall,
  },
  {
    path: '/hsinchu-hall',
    layout: DefaultLayout,
    component: HsinchuHall,
  },
  {
    path: '/tainan-hall',
    layout: DefaultLayout,
    component: TainanHall,
  },
  {
    path: '/book',
    layout: DefaultLayout,
    component: Book,
  },
  {
    layout: DefaultLayout,
    component: () => <Redirect to="/" />,
  },
];
