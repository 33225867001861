import _ from 'lodash';
import useApi, { API_METHOD } from '../hooks/use-api';
import { MEMORABILIA } from './API';

const useMemorabilia = () => {
    const { FetchApi } = useApi();
  
    const getMemorabilia = async () => {
      const { status, message, result } = await FetchApi(API_METHOD.GET, MEMORABILIA, null);
      return { status, message, result };
    };
  
    return { getMemorabilia };
  };
  
  export default useMemorabilia;