//首頁最新消息
export const TOP_NEWS_LIST = '/News/topNewsList';

//首頁婦幼專區
export const TOP_WOMEN_CHILDREN_LIST = '/WomenChildren/topWomenChildrenList';
export const MOM_BABY_DETAIL = 'WomenChildren/';

//最新消息
export const NEWS_LIST = 'News/newsList';
export const NEWS = 'News/';

//母嬰資訊
export const MOM_BABY_INFO_LIST = 'WomenChildren/womenChildrenList/0';
export const MOM_BABY_INFO = 'WomenChildren/';

//暖心紀錄
export const WARM_RECORD_LIST = 'WomenChildren/womenChildrenList/2';
export const WARM_RECORD = 'WomenChildren/';

//媽媽教室
export const MOM_CLASSROOM_LIST = 'WomenChildren/womenChildrenList/1';
export const MOM_CLASSROOM = 'WomenChildren/';

//醫護陣容
export const MEDICAL_TEAM_LIST = 'MedicalTeam/medicalTeamList';
export const MEDICAL_TEAM_DETAIL = 'MedicalTeam/';

//大事紀
export const MEMORABILIA = 'Memorabilia/';

//預約資訊
export const RESERVE_INFO_CHECK = 'ReserveInfo/check';
export const RESERVE_INFO = 'ReserveInfo';
