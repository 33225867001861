import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
import { css } from '@emotion/core';

const CustomFormCheckbox = (props) => {
  const { className, children, inline, valid, invalid, innerRef, toggle, small, id: _id, indeterminate, ...attrs } = props;

  const defaultRef = useRef();

  useEffect(() => {
    innerRef && innerRef(defaultRef.current);
  }, []);

  useEffect(() => {
    indeterminate && (defaultRef.current.indeterminate = indeterminate);
  }, [defaultRef, indeterminate]);

  const labelClasses = classNames(
    className,
    'custom-control',
    !toggle ? 'custom-checkbox' : 'custom-toggle',
    toggle && small && 'custom-toggle-sm',
    inline && 'custom-control-inline',
    valid && 'is-valid',
    invalid && 'is-invalid'
  );

  const inputClasses = classNames('custom-control-input', valid && 'is-valid', invalid && 'is-invalid');

  const id = _id || `dr-checkbox-${shortid.generate()}`;

  const handleClick = (e) => {
    !attrs.disabled && defaultRef.current.click();
    // props.onChange();
  };

  return (
    <label css={styles} className={labelClasses}>
      <input {...attrs} ref={defaultRef} id={id} type="checkbox" className={inputClasses} />
      <label id={id} className="custom-control-label" aria-hidden="true" onClick={handleClick} />
      <span className="custom-control-description">{children}</span>
    </label>
  );
};

CustomFormCheckbox.defaultProps = {
  disabled: false,
  onChange: () => {},
};

CustomFormCheckbox.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * Whether it is a toggle button, or not.
   */
  toggle: PropTypes.bool,
  /**
   * Whether it is small (toggle), or not.
   */
  small: PropTypes.bool,
  /**
   * The onChange handler.
   */
  onChange: PropTypes.func,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
};

export default CustomFormCheckbox;

const styles = css`
  margin: 0;
  padding-left: 18px;
  .custom-control-label::before {
    border-color: #52b54f;
    background-color: #f7f4ee;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #52b54f;
  }
`;
