import React, { useEffect, useState } from 'react';
import { Nav } from 'shards-react';
import { css } from '@emotion/core';

import NavbarItem from './NavbarItem';

import useLocalState from '../../../hooks/use-local-state';
import useRouter from '../../../hooks/use-router';
import images from '../../../images/images';
import _ from 'lodash';

const NavbarItems = () => {
  const router = useRouter();
  const { mainItems: items } = useLocalState();
  const [hover, setHover] = useState([false, false, false, false, false]);

  const handleMouseEnter = (index) => {
    hover[index] = true;
    setHover([...hover]);
  };

  const handleMouseLeave = (index) => {
    hover[index] = false;
    setHover([...hover]);
  };
  return (
    <div
      css={styles}
      style={{
        display: 'flex',
        height: '100%',
        marginTop: '43px',
        paddingLeft: '5px',
        paddingRight: '28px',
        overflowX: 'clip',
      }}
    >
      {items?.map((nav, idx) => (
        <div
          key={idx}
          style={{ position: 'relative' }}
          onMouseEnter={() => handleMouseEnter(idx)}
          onMouseLeave={() => handleMouseLeave(idx)}
          onClick={() => idx == 5 ? window.open(nav.to, '_blank') : router.push(nav.to)}
        >
          <h6 className={`${nav?.items && 'nav-item-drop'} nav-item-title`}>
            {nav.title}
          </h6>
          {hover[idx] && typeof nav.items !== 'undefined' && nav.items.length && (
            <Nav
              className={`nav--no-borders ${idx != 1 && 'flex-column'}  mt-2`}
              style={{
                borderRadius: '6px',
                position: 'absolute',
                transform: 'translate(-50%, 0)',
                minWidth: 100,
                left: '50%',
                textAlign: 'center',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              {nav.items?.map((item, idx) => (
                <NavbarItem key={idx} item={item} />
              ))}
            </Nav>
          )}
        </div>
      ))}
    </div>
  );
};

export default NavbarItems;

const styles = css`
  .nav-item-title {
    color: white;
    margin: 0 calc(10px + 1vw);
    font-weight: 300;
    font-size: 0.85vw;
    @media (max-width: 1660px) {
      margin: 0 23px;
    }
    @media (max-width: 1390px) {
      margin: 0 19px;
    }
    @media (max-width: 1260px) {
      margin: 0 15px;
    }
    @media (max-width: 1150px) {
      margin: 0 11px;
    }
    @media (max-width: 1060px) {
      margin: 0 9px;
    }
  }
  .nav-item-drop::after {
    content: url(${images.drop_down});
    position: absolute;
    top: 13%;
    right: 12px;
    transform: translateY(-50%);
    @media (max-width: 1390px) {
      right: 3px;
    }
    @media (max-width: 1060px) {
      content: '';
    }
  }
`;
