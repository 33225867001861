import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
import { css } from '@emotion/core';

const CustomFormRadio = (props) => {
  const { className, children, inline, valid, invalid, innerRef, onChange, id: _id, ...attrs } = props;

  const defaultRef = useRef();

  useEffect(() => {
    innerRef && innerRef(defaultRef.current);
  }, []);

  const labelClasses = classNames(
    'custom-control',
    'custom-radio',
    inline && 'custom-control-inline',
    valid && 'is-valid',
    invalid && 'is-invalid'
  );

  const inputClasses = classNames(className, 'custom-control-input', valid && 'is-valid', invalid && 'is-invalid');

  const id = _id || `dr-radio-${shortid.generate()}`;

  const handleClick = (e) => {
    // onChange();
    defaultRef.current.click();
  };

  return (
    <label css={styles} className={labelClasses}>
      <input {...attrs} ref={defaultRef} id={id} type="radio" className={inputClasses} onChange={onChange} />
      <label id={id} className="custom-control-label" aria-hidden="true" onClick={handleClick} />
      <span className="custom-control-description">{children}</span>
    </label>
  );
};

const styles = css`
  .custom-control-label::before {
    border-color: #52b54f;
    background-color: #F7F4EE;
  }
`;

CustomFormRadio.defaultProps = {
  onChange: () => {},
};

CustomFormRadio.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children.
   */
  children: PropTypes.node,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * The function that should run on change.
   */
  onChange: PropTypes.func,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
};

export default CustomFormRadio;
