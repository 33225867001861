import React from 'react';
import { Container, Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import ReactShadowScroll from 'react-shadow-scroll';
import _ from 'lodash';
import { BounceLoader } from 'react-spinners';

import images from '../../images/images';
import { CustomListItem, MobileCustomListItem } from '.';

const ListContainer = ({ isFirst, backgroundImage, title, subtitle, loading, datas, ...props }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <div className="custom-list" style={{ marginTop: '-90px' }}>
      <Container
        fluid
        className="main-content-container p-0 home-background"
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: isMobile ? 'center center' : '-25vw' }}
      >
        <div
          className={`view-side-left ${isFirst ? 'elementToFadeIn1' : ''}`}
          style={{ backgroundImage: `url(${images.img_01_a})` }}
        >
          <div className={`${isFirst ? 'elementToFadeIn1' : ''}`}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
        </div>
        <div className={`${!isMobile && 'view-side-right'} `} style={{ backgroundColor: '#F7F4EE' }}></div>
        <div className={`${isMobile ? 'mobile-custom-list-view' : 'custom-list-view'} ${isFirst ? 'elementToFadeIn1' : ''}`}>
        {(!loading && isMobile) && <img style={{position: 'absolute', zIndex: '20', right: 20, top: '50%', height: '38px'}} 
          src={images.next_g_01} />}
          <ReactShadowScroll
            style={
              isMobile
                ? { width: '100vw', maxWidth: '100vw', maxHeight: 'calc(100vh - 260px)' }
                : { height: 'calc(100vh - 140px)' }
            }
            isShadow={false}
            scrollWidth={0}
            scrollColor="rgba(255,255,255,0)"
          >
            {isMobile ? (
              <Row style={{ width: 'max-content', marginLeft: 20 }}>
                {datas && _.map(datas, (o, i) => <MobileCustomListItem {...o} key={i} />)}
              </Row>
            ) : (
              datas && _.map(datas, (o, i) => <CustomListItem {...o} key={i} />)
            )}
          </ReactShadowScroll>
        </div>
      </Container>
      {loading && (
        <div className="loading-view">
          <BounceLoader size={150} color={'#52B54F'} loading={loading} />
        </div>
      )}
    </div>
  );
};

export default ListContainer;
