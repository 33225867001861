export default {
    DIGITS: /^[0-9]+$/,
    ALPHABETIC_CHARACTERS: /^[a-zA-Z]+$/,
    ALPHA_NUMERIC_CHARACTERS: /^[a-zA-Z0-9]+$/,
    EMAIL: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    IDNO: /^[A-Z]{1}[0-9]{9}$/,
    PERMITNO: /^[A-Z]{2}[0-9]{8}$/,
    EMPLOYEEID: /^[a-zA-Z0-9]{1,10}$/,
    MOBILE: /^\d{10}$/,
  };
  