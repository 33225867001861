import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import useNews from '../../api/news-service';
import useRouter from '../../hooks/use-router';
import { ListPopUp, ListContainer } from '../../components/custom-list';

const News = () => {
  const [state, produce] = useImmer({
    ...location.state,
    isFirst: true,
    loading: true,
    list: null,
    detail: null,
    defaultKeywords: null,
    keywords: '國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心,台北 產後護理之家,台南 產後護理之家,松山區 月子中心,台北 月子中心,中西區 月子中心,台南 月子中心,月子中心 比較,月子中心 推薦,月子中心 評價,產後護理之家 推薦,產後護理之家 評價'
  });

  const { isFirst, list, loading, detail, defaultKeywords, keywords } = state;
  const {
    query: { id },
    push,
  } = useRouter();

  const { getNews, getNewsDetail } = useNews();

  useEffect(() => {
    getList();
    produce((draft) => { draft.defaultKeywords = keywords });
  }, []);

  useEffect(() => {
    if (!id) return;
    getDetail(id);
  }, [id]);

  const getList = async () => {
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getNews();
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => void (draft.list = result));
    }
  };

  const getDetail = async (id) => {
    if (!!detail) return;
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getNewsDetail(id);
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => { 
        draft.detail = result 
        draft.keywords = result.keywords
      });
    }else {
      push('/news');
    }
  };

  return (
    <div>
      <MetaTags>
        <title>最新消息｜最新活動、課程與客戶入住推薦分享｜國泰產後護理之家</title>
        <meta name="description" content="國泰產後護理之家最新活動、媽媽教室課程，以及名人推薦、媽咪分享、網紅開箱等，最新資訊都在這裡，歡迎觀看、分享與深入瞭解。" />
        <meta
          name="keywords"
          content={keywords}
        />
      </MetaTags>
      <ListContainer
        backgroundImage={images.background_01_b}
        title="最新消息"
        subtitle="關於國泰產後護理之家的新訊"
        isFirst={isFirst}
        loading={loading}
        datas={_.map(list, (o) => ({
          id: o.id,
          title: o.title,
          image: o.imagePath,
          intro: o.briefIntro,
        }))}
      />
      {detail && !loading && (
        <ListPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => {
              draft.detail = null
              draft.keywords = defaultKeywords
            });
            push('/news');
          }}
          detail={detail}
        />
      )}
    </div>
  );
};

export default News;
