import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { css } from '@emotion/core';

import { createClient } from './utils/apollo';
import { LocalStateProvider } from './hooks/use-local-state';
import { AuthProvider } from './hooks/use-auth';
import { ApiProvider } from './hooks/use-api';
import routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default () => (
  <ApolloProvider client={createClient()}>
    <LocalStateProvider>
      <AuthProvider>
        <ApiProvider>
          <Router>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  )}
                />
              ))}
            </Switch>
          </Router>
        </ApiProvider>
      </AuthProvider>
    </LocalStateProvider>
  </ApolloProvider>
);
