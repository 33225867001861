import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import useMomBaby from '../../api/mom-baby-service';
import useRouter from '../../hooks/use-router';
import { ListPopUp, ListContainer } from '../../components/custom-list';

const MomClassrooms = () => {
  const [state, produce] = useImmer({
    ...location.state,
    isFirst: true,
    loading: true,
    list: null,
    detail: null,
    defaultKeywords: null,
    keywords: '國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心,台北 產後護理之家,台南 產後護理之家,媽媽教室,月子中心 評價,產後護理之家 評價,松山區 月子中心,台北 月子中心,中西區 月子中心,台南 月子中心,月子中心 媽媽教室,產後護理之家 媽媽教室'
  });

  const { isFirst, list, loading, detail, defaultKeywords, keywords } = state;
  const {
    query: { id },
    push,
  } = useRouter();

  const { getMomClassrooms, getMomClassroomsDetail } = useMomBaby();

  useEffect(() => {
    getList();
    produce((draft) => { draft.defaultKeywords = keywords });
  }, []);

  useEffect(() => {
    if (!id) return;
    getDetail(id);
  }, [id]);

  const getList = async () => {
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMomClassrooms();
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => void (draft.list = result));
    }
  };

  const getDetail = async (id) => {
    if (!!detail) return;
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMomClassroomsDetail(id);
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => { 
        draft.detail = result 
        draft.keywords = result.keywords
      });
    }
  };

  return (
    <div>
      <MetaTags>
        <title>媽媽教室｜媽媽教室上課資訊與紀錄｜國泰產後護理之家</title>
        <meta name="description" content="國泰產後護理之家提供孕期營養大解密、追奶實務技巧、返家照護必修、奶爸訓練、親子共讀、DIY手作，以及FUN輕鬆瑜珈等多元豐富媽媽教室課程，立即瞭解！" />
        <meta
          name="keywords"
          content={keywords}
        />
      </MetaTags>
      <ListContainer
        backgroundImage={images.background_01_b}
        title="媽媽教室"
        subtitle="媽媽教室上課資訊與紀錄"
        isFirst={isFirst}
        loading={loading}
        datas={_.map(list, (o) => ({
          id: o.id,
          title: o.title,
          image: o.imagePath,
          intro: o.briefIntro,
          tab: '媽媽教室',
        }))}
      />
      {detail && !loading && (
        <ListPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => {
              draft.detail = null
              draft.keywords = defaultKeywords
            });            
            push('/mom-classrooms');
          }}
          detail={detail}
        />
      )}
    </div>
  );
};

export default MomClassrooms;
