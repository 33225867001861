import { useState, useEffect, useCallback } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(localStorage.getItem(key) || initialValue);

  const setItem = newValue => {
    setValue(newValue);
    localStorage.setItem(key, newValue);
  };

  const handleStorage = useCallback(
    event => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [value]
  );

  useEffect(() => {
    addEventListener('storage', handleStorage);
    return () => removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [value, setItem];
};

export default useLocalStorage;
