import React from 'react';
import { Row, Col } from 'shards-react';
import useRouter from '../../hooks/use-router';
import LazyLoad from 'react-lazy-load';

const CustomListItem = ({ title, image, intro, id, tab }) => {
  const { push, pathname } = useRouter();
  return (
    <Col>
      <Row className="custom-list-item" onClick={() => push(`${pathname}/${id}`)}>
        <Col>
          <LazyLoad height={215} offsetVertical={600}>
            <img src={`${process.env.REACT_APP_SERVER_HOST}${image}`} />
          </LazyLoad>
          {tab && <div className="item-tab">{tab}</div>}
        </Col>
        <Col className="custom-list-item-col">
          <div>
            <label>{title}</label>
            <p>{intro}</p>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default CustomListItem;
