import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Nav, NavItem, NavLink } from 'shards-react';
import { Link as _Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Link = (props) => <_Link {...props} />;

const MainFooter = ({ contained, menuItems, copyright, className, blackColor = false}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <footer
      className={`main-footer d-flex p-2 px-3 ${className}`}
      style={{
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: 1500,
        whiteSpace: 'pre-line',
        textAlign: 'center',
      }}
    >
      <Container fluid={contained} style={{ alignSelf: 'center' }}>
        <Row style={{ justifyContent: 'center' }}>
          {/* <Nav>
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={Link} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav> */}
          <span className="copyright" style={{ fontSize: '12px', fontWeight: '400', paddingBottom: isMobile ? '0px' : '', color: blackColor ? '#aaaaaa' : '#ffffff' }}>
            {copyright}
          </span>
        </Row>
      </Container>
    </footer>
  );
};

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: false,
  copyright: 'Copyright © 2018 DesignRevision',
  menuItems: [
    {
      title: 'Home',
      to: '#',
    },
    {
      title: 'Services',
      to: '#',
    },
    {
      title: 'About',
      to: '#',
    },
    {
      title: 'Products',
      to: '#',
    },
    {
      title: 'Blog',
      to: '#',
    },
  ],
};

export default MainFooter;
