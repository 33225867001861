import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import useMomBaby from '../../api/mom-baby-service';
import useRouter from '../../hooks/use-router';
import { ListPopUp, ListContainer } from '../../components/custom-list';

const MomBabyInfos = () => {
  const [state, produce] = useImmer({
    ...location.state,
    isFirst: true,
    loading: true,
    list: null,
    detail: null,
    defaultKeywords: null,
    keywords: "國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心,台北 產後護理之家,台南 產後護理之家,月子中心 評價,產後護理之家 評價,松山區 月子中心,台北 月子中心,中西區 月子中心,台南 月子中心		"
  });

  const { isFirst, list, loading, detail, defaultKeywords, keywords } = state;
  const {
    query: { id },
    push,
  } = useRouter();

  const { getMomBabyInfos, getMomBabyInfosDetail } = useMomBaby();

  useEffect(() => {
    getList();
    produce((draft) => { draft.defaultKeywords = keywords });
  }, []);

  useEffect(() => {
    if (!id) return;
    getDetail(id);
  }, [id]);

  const getList = async () => {
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMomBabyInfos();
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => void (draft.list = result));
    }
  };

  const getDetail = async (id) => {
    if (!!detail) return;
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMomBabyInfosDetail(id);
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => { 
        draft.detail = result 
        draft.keywords = result.keywords
      });
    }
  };

  return (
    <div>
      <MetaTags>
        <title>母嬰資訊｜傳遞正確母嬰知識協助育兒更輕鬆｜國泰產後護理之家</title>
        <meta name="description" content="剖腹產吃什麼恢復最快?如何成功哺乳，擊退塞奶大魔王！孕哺期間營養品怎麼吃？新手爸媽養成計劃如何開始?所有新手爸媽最想知道的母嬰正確衛教資訊告訴你！ " />
        <meta
          name="keywords"
          content={keywords}
        />
      </MetaTags>
      <ListContainer
        backgroundImage={images.background_01_b}
        title="母嬰資訊"
        subtitle="婦幼影音資訊"
        isFirst={isFirst}
        loading={loading}
        datas={_.map(list, (o) => ({
          id: o.id,
          title: o.title,
          image: o.imagePath,
          intro: o.briefIntro,
          tab: '母嬰資訊',
        }))}
      />
      {detail && !loading && (
        <ListPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => {
              draft.detail = null
              draft.keywords = defaultKeywords
            });   
            push('/mom-baby-infos');
          }}
          detail={detail}
        />
      )}
    </div>
  );
};

export default MomBabyInfos;
