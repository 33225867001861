import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/core';
import { Row, Col } from 'shards-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import _ from 'lodash';
import 'react-vertical-timeline-component/style.min.css';

const MemorabiliaTimeline = ({ datas }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 1250px)' });
    //根據●換行
    const convertWrapWord = (val) => {
      let convertWord = String(val).split('●');
      if(convertWord.length == 1) return val;
      return (
        <>
        {
          _.map(convertWord, (content, idx) => (
            idx != 0 && (
            <>
              {`●${content}`}<br/>
            </>)
          ))
        }
        </>
      )
    }

    return (
      <div css={styles}>
      {isMobile?
      <Col>
      {
          _.map(datas, (item, index) => (
            <div key={index} className="mobile-item-container">
                <div>
                    <span className="memorabilia-diamod"></span>
                    <div className="memorabilia-content">
                        <h2 className="ertical-timeline-element-title">{item.year}年</h2>
                        <p>
                        {convertWrapWord(item.content)}
                        </p>
                    </div>
                </div>
                <img width="226" height="170" src={`${process.env.REACT_APP_SERVER_HOST}${item.imagePath}`}/>
            </div>
        ))
      }
      </Col>
      :
        <VerticalTimeline>
          {
            _.map(datas, (item, index) => (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F7F4EE'}}
              >
                <Row>
                  <img width="226" height="170" style={{ display: index%2 == 1 ? '' : 'none'}} src={`${process.env.REACT_APP_SERVER_HOST}${item.imagePath}`}></img>
                  <Col>
                    <div className="memorabilia-content-div">
                      <span className="memorabilia-diamod"></span>
                      <div className="memorabilia-content">
                        <h2 className="vertical-timeline-element-title">{item.year}年</h2>
                        <p>
                        {convertWrapWord(item.content)}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <img width="226" height="170" style={{ display: index%2 == 0 ? '' : 'none'}} src={`${process.env.REACT_APP_SERVER_HOST}${item.imagePath}`}></img>
                </Row>
              </VerticalTimelineElement>
            ))
          }
        </VerticalTimeline>
      }
      </div>
    );
};
  
export default MemorabiliaTimeline;

const styles = css`

.vertical-timeline.vertical-timeline--two-columns {
  width: 100%
}

.vertical-timeline::before {
  width: 8px;
  border-radius: 4px;
  background: #C5C1BA;
  @media (max-width: 1480px) {
    display: none;
  }
}

.vertical-timeline-element-icon {
  background: rgb(255, 255, 255);
  border:6px solid #C5C1BA;
  width: 22px;
  height: 22px;
  margin-left: -9px;
  -webkit-box-shadow: 0 0 0 0px #fff;
  box-shadow: 0 0 0 0px #fff;
  top: 20px;
  @media (max-width: 1480px) {
    display: none;
  }
}

.vertical-timeline-element-content-arrow {
  border-right: 0;
  width: 32px;
  top: 25px;
  
  background-size: 20px 2px;
  background-repeat: repeat-x;
  @media (max-width: 1690px) {
    width: 33px;
  }
  @media (min-width: 1561px) {
    background-image: linear-gradient(to right, #C5C1BA 0%, #C5C1BA 50%, transparent 50%);
  }
}

.vertical-timeline-element-content {
  background: '#F7F4EE';
  box-shadow: 0 0px 0 #ddd;
  -webkit-box-shadow: 0 0px 0 #ddd;
  padding-top: 0px;
  width: 46%;
  @media (max-width: 1560px) {
    width: 48%;
  }
  @media (max-width: 1480px) {
    width: 58%;
  }
  @media (max-width: 1350px) {
    width: 66%;
  }
  @media (max-width: 1250px) {
    width: 78%;
  }
}

.vertical-timeline-element-content img {
  border-radius: 6px;
}

.memorabilia-content-div {
  display:flex; 
  justify-content:center; 
  align-items:center;
  text-align: center;
}

.memorabilia-content {
  @media (min-width: 1251px) {
    top: 40px;
    position: absolute;
  }
  @media (max-width: 1250px) {
    min-height: 170px;
    margin-bottom: 15px;
    padding-top: 40px;
    position: relative;
  }
}

.vertical-timeline-element-title {
  font-family: PingFangTC-Medium;
  letter-spacing: 1px;
}

.memorabilia-content p {
  font-family: PingFangTC-Regular;
  text-align: justify;
  font-weight: 400;
  line-height: normal;
  max-width: 170px;
}

.memorabilia-diamod {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 2px solid #C5C1BA;
  transform: rotate(45deg);
  opacity: 0.5;
  @media (min-width: 1251px) {
    top: 15%;
  }
  @media (max-width: 1250px) {
    transform-origin: 0% 0%;
  }
}

.mobile-item-container {
    margin-top: 20px;
    text-align: center;
    text-align: -webkit-center;
}

.mobile-item-container img {
    border-radius: 6px;
}

`;