import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';

import useLocalState from '../../../hooks/use-local-state';

const SidebarMainNavbar = ({ hideLogoText }) => {
  const { toggleSidebar } = useLocalState();

  return (
    <div className="main-navbar">
      <Navbar className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0" type="light">
        <NavbarBrand
          className="w-100 mr-0"
          href="#"
          style={{ lineHeight: '25px', backgroundColor: 'rgba(247,244,238,1)' }}
        ></NavbarBrand>
        {/* eslint-disable-next-line  d-md-none*/}
        <a className="toggle-sidebar d-sm-inline d-lg-none" onClick={toggleSidebar}>
          <i className="material-icons" style={{ fontSize: '28px' }}>
            &#xe5cd;
          </i>
        </a>
      </Navbar>
    </div>
  );
};

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
