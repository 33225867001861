import _ from 'lodash';
import useApi, { API_METHOD } from '../hooks/use-api';
import {
  TOP_WOMEN_CHILDREN_LIST,
  MOM_BABY_DETAIL,
  MOM_BABY_INFO,
  MOM_BABY_INFO_LIST,
  WARM_RECORD_LIST,
  WARM_RECORD,
  MOM_CLASSROOM_LIST,
  MOM_CLASSROOM,
} from './API';

const useMomBaby = () => {
  const { FetchApi } = useApi();

  const getTopMomBabyInfos = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, TOP_WOMEN_CHILDREN_LIST, null);
    return { status, message, result };
  };

  const getTopMomBabyInfosDetail = async (id) => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, `${MOM_BABY_DETAIL}${id}`, null);
    return { status, message, result };
  };

  const getMomBabyInfos = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, MOM_BABY_INFO_LIST, null);
    return { status, message, result };
  };

  const getMomBabyInfosDetail = async (id) => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, `${MOM_BABY_INFO}${id}`, null);
    return { status, message, result };
  };

  const getWarmRecords = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, WARM_RECORD_LIST, null);
    return { status, message, result };
  };

  const getWarmRecordsDetail = async (id) => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, `${WARM_RECORD}${id}`, null);
    return { status, message, result };
  };

  const getMomClassrooms = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, MOM_CLASSROOM_LIST, null);
    return { status, message, result };
  };

  const getMomClassroomsDetail = async (id) => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, `${MOM_CLASSROOM}${id}`, null);
    return { status, message, result };
  };

  return {
    getTopMomBabyInfos,
    getTopMomBabyInfosDetail,
    getMomBabyInfos,
    getMomBabyInfosDetail,
    getWarmRecords,
    getWarmRecordsDetail,
    getMomClassrooms,
    getMomClassroomsDetail,
  };
};

export default useMomBaby;
