import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';
import { css } from '@emotion/core';
import { useImmer } from 'use-immer';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';
import PrivacyPopUp from '../../../components/custom-list/PrivacyPopUp';

import useLocalState from '../../../hooks/use-local-state';

const MainSidebar = (props) => {
  const [state, produce] = useImmer({
    ...location.state,
    isPrivacy: false,
  });
  const { menuVisible } = useLocalState();
  const classes = classNames('main-sidebar', 'px-0', 'col-12', menuVisible && 'open');
  const { isPrivacy } = state;
  const viewHeight = window.innerHeight;
  const [height, setHeight] = useState(null);
  const midHeight = useCallback(node => {
    if (node !== null) {
      setHeight(viewHeight - node.getBoundingClientRect().height - 91);
    }
  }, []);
  return (
    // md={{ size: 3 }}
    <Col tag="aside" className={classes} lg={{ size: 2 }} style={{ zIndex: 3000, height: viewHeight }} css={styles}>
      <SidebarMainNavbar hideLogoText={props.hideLogoText} />
      <SidebarNavItems height={height} />
      <div className="footer-div" ref={midHeight}>
        {/* <div>
          <span>國泰台北會館產後護理之家 / 02-2748-5111 /</span>
          <span>台北市松山區健康路156號7.8樓</span>
        </div>
        <div>
          <span>國泰台南產後護理之家 / 06-215-0599 /</span>
          <span>台南市中西區永福路一段185號3.4樓</span>
        </div> */}
        <div className="mx-1 footer-font-size">
          <span>
            服務信箱：<a href={"mailto:cathaybabyservice@cathay-hcm.com.tw"}>cathaybabyservice@cathay-hcm.com.tw</a> /{' '}
            <span
              style={{ cursor: 'pointer', display: 'inline' }}
              onClick={() => {
                produce((draft) => {
                  draft.isPrivacy = true;
                });
              }}
            >
              隱私權政策
            </span>{' '}
            / 版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰竹教產後護理之家 | 國泰台南產後護理之家 Copyright © Cathay Healthcare Inc., Ltd. All Rights
            Reserved.
          </span>
        </div>
      </div>
      <PrivacyPopUp
        isShowDialog={isPrivacy}
        overlayAction={() => {
          produce((draft) => {
            draft.isPrivacy = false;
          });
        }}
      />
    </Col>
  );
};

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;

const styles = () => css`
  .footer-div {
    position: absolute;
    bottom: 0;
    z-index: 9999 !important;
  }
  .footer-div div {
    margin-bottom: 10px;
  }
  .footer-div span {
    display: flow-root;
    text-align: center;
    font-weight: 300;
  }
  .css-1cnr01f-styles .subcontainer {
    padding-right: 0px !important;
  }
  .footer-font-size{
    font-weight: 300;
    font-size: 1.5vw;
  }
`;
