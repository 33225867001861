import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';

import MainNavbar from '../components/layout/MainNavbar';
import MainSidebar from '../components/layout/MainSidebar';
import MainFooter from '../components/layout/MainFooter';
import images from '../images/images';

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  return (
    <Container
      fluid
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${images.background_01_a})`,
        backgroundPosition: 'center',
      }}
    >
      <Row>
        {isMobile && <MainSidebar />}
        <Col className="main-content p-0" sm="12" tag="main">
          {!noNavbar && <MainNavbar />}
          {children}
          {/* {!noFooter && (
          <MainFooter copyright="版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰台南產後護理之家 Copyright © Cathay Healthcare Inc., Ltd. All Rights Reserved." />
        )} */}
        </Col>
      </Row>
    </Container>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
