import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { Button, Container, Card, CardImg, CardBody, CardTitle, Row, Col } from 'shards-react';
import { css } from '@emotion/core';
import ProgressBar from 'react-customizable-progressbar';
import ReactPageScroller from '../../components/react-page-scroller/src/index';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { BounceLoader } from 'react-spinners';
import MetaTags from 'react-meta-tags';

import useNews from '../../api/news-service';
import useMomBaby from '../../api/mom-baby-service';
import useRouter from '../../hooks/use-router';
import MainFooter from '../../components/layout/MainFooter';
import images from '../../images/images';
import { ListPopUp } from '../../components/custom-list';
import PrivacyPopUp from '../../components/custom-list/PrivacyPopUp';
import AllRoundContainer from './AllRoundContainer';

const Home = () => {
  const [state, produce] = useImmer({
    ...location.state,
    loading: true,
    loading2: false,
    progress: 0,
    currentPage: 0,
    isFirst: true,
    newsList: null,
    detail: null,
    
    momList: null,
    isPrivacy: false,
  });
  const [seconds, setSeconds] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const { getTopNews, getNewsDetail } = useNews();
  const { getTopMomBabyInfos, getTopMomBabyInfosDetail } = useMomBaby();
  const {
    query: { id },
    push,
    pathname,
  } = useRouter();

  const { loading, loading2, progress, currentPage, isFirst, newsList, detail, momList, isPrivacy } = state;
  const refNewsScrollView = React.createRef();
  const refCareScrollView = React.createRef();
  const refMomScrollView = React.createRef();

  const communityDatas = [
    { imgName: 'facebook', link: 'https://www.facebook.com/CathayBabyCare/' },
    { imgName: 'ig', link: 'https://www.instagram.com/cathaybabycare/' },
    { imgName: 'youtube', link: 'https://www.youtube.com/channel/UC9k07d0-tS73S13hZiMXcSg' },
    { imgName: 'line', link: 'https://page.line.me/981hiwda?openQrModal=true', text: '台北' },
    { imgName: 'line', link: 'https://page.line.me/?accountId=958aibfa&openQrModal=true', text: '內湖' },
    { imgName: 'line', link: 'https://page.line.me/?accountId=870mrciq&openQrModal=true', text: '新竹' },
    { imgName: 'line', link: 'https://page.line.me/219pheuu?openQrModal=true', text: '台南' },
  ];
  const CareListOne = [
    {
      backgroundImage: images.care_05,
      title: '寶寶呵護',
      body: '1：5專業醫護陣容，全護理師24小時照護。',
      path: '/baby',
    },
    {
      backgroundImage: images.care_06,
      title: '營養膳食',
      body: '營養師一對一諮詢飲食建議，獨立廚房新鮮現煮。',
      path: '/diet',
    },
  ];
  const CareListTwo = [
    {
      backgroundImage: images.care_03,
      title: '醫護陣容',
      body: '婦產科、小兒科醫師定期巡診，醫療後送制度嚴謹完善。',
      path: '/medical-lineup',
    },
    {
      backgroundImage: images.care_04,
      title: '媽媽照護',
      body: '專業營養師、護理師、瑜珈課程，呵護媽咪身心。',
      path: '/mother',
    },
  ];
  const MomType = ['母嬰資訊', '媽媽教室', '暖心紀錄'];
  const ShareList = [
    {
      title: '無論是對媽咪或baby，都讓我們感到很溫暖',
      content: [
        '敬愛的護理師們',
        '謝謝妳們這段時間的照顧，無論是對媽咪或baby，都讓我們感到很溫暖！我們家寶寶淺眠、不好睡覺的體質，相信也為妳們帶來不少困擾，但妳們依舊一視同仁的照顧著，且也與我分享許多安撫的方式與經驗，在在都令人感動，僅以這份蛋糕表達我們的感謝，有妳們真好，真心感謝唷～辛苦了！',
        '312的媽咪、爸比下台一鞠躬（莫名想哭呀）。',
      ],
    },
    {
      title: '日後若有第二胎，一定會再選這裡',
      content: [
        '護理人員都很熱心負責，且對於新手媽媽的問題都很貼心得仔細回覆，營養師的諮詢時間及課程也很受益，客服也提供良好的服務，整體感覺非常好，連清潔阿姨也會稍微關心問候，並時而關心。平安夜收到小朋友攝影及嬰兒室製做的照片，覺得很溫馨，日後若有第二胎，一定會再選這裡。ps.出住收到寶寶成長日記很開心。',
      ],
    },
    {
      title: '兩胎皆入住國泰的最佳肯定!',
      content: [
        '第一胎就在國泰產後得到很多的寶寶照護技巧，尤其新手媽媽又很焦慮，也常得到護理師的關懷和安慰，倍感窩心，覺得國泰對媽寶都很用心，所以生二寶也再度選擇國泰產後護理之家!',
        '為了感謝護理人員的照顧，出住前送給大家護理師造型的糖霜餅乾，表達我內心的感謝!',
      ],
    },
  ];

  useEffect(() => {
    callApis();
  }, []);

  useEffect(() => {
    if (progress == 100) {
      setTimeout(() => {
        produce((draft) => {
          draft.loading = false;
        });
      }, 1000);
    }
  }, [progress]);

  useEffect(() => {
    if (!id) return;
    if (currentPage == 1) {
      getDetail(id);
    } else if (currentPage == 3) {
      getMomDetail(id);
    }
  }, [id]);

  const getDetail = async (id) => {
    if (!!detail) return;
    produce((draft) => void (draft.loading2 = true));
    const { status, message, result } = await getNewsDetail(id);
    produce((draft) => void (draft.loading2 = false));
    if (status == '1') {
      produce((draft) => void (draft.detail = result));
    } else {
      push('/');
    }
  };
  const getMomDetail = async (id) => {
    if (!!detail) return;
    produce((draft) => void (draft.loading2 = true));
    const { status, message, result } = await getTopMomBabyInfosDetail(id);
    produce((draft) => void (draft.loading2 = false));
    if (status == '1') {
      produce((draft) => void (draft.detail = result));
    } else {
      push('/');
    }
  };

  const getList = async () => {
    const { status, message, result } = await getTopNews();
    if (status == '1') {
      // console.log("[Test] result :",result);
      produce((draft) => void (draft.newsList = result));
    }
  };
  const getListMomBaby = async () => {
    const { status, message, result } = await getTopMomBabyInfos();
    if (status == '1') {
      produce((draft) => void (draft.momList = result));
    }
  };
  const callApis = async () => {
    let progress = 0;

    const pArr = [getList(), getListMomBaby()];
    pArr.forEach((p) => {
      p.then(() => {
        progress += 50;
        produce((draft) => {
          draft.progress = progress;
        });
      });
    });
    // await new Promise((resolve) => setTimeout(() => resolve(), 2500));
  };

  const handleCommunityClick = (url) => {
    window.open(url, '_blank');
  };

  const handleNewsArrowClick = () => {
    const offset = refNewsScrollView.current.scrollLeft + 340;
    refNewsScrollView.current.scrollTo({top: 0, left: offset, behavior: 'smooth', block: 'start' }); 
  }
  const handleCareArrowClick = () => {
    const offset = refCareScrollView.current.scrollLeft + 240;
    refCareScrollView.current.scrollTo({top: 0, left: offset, behavior: 'smooth', block: 'start' }); 
  }
  const handleMomArrowClick = () => {
    const offset = refMomScrollView.current.scrollLeft + 340;
    refMomScrollView.current.scrollTo({top: 0, left: offset, behavior: 'smooth', block: 'start' }); 
  }

  const handlePageChange = (e) => {
    produce((draft) => {
      draft.currentPage = e;
    });
    if (e > 0) {
      produce((draft) => {
        draft.isFirst = false;
      });
    }
  };

  const CommunityItem = ({ item }) => {
    return (
      <div className="home-community-icon" onClick={() => handleCommunityClick(item.link)}>
        <img src={images[item.imgName]} alt="facebook" />
        <span>{item.text}</span>
      </div>
    );
  };

  const goTopPage = () => {
    if(currentPage === 4) produce(draft => {draft.currentPage = 0});
    else return
  }
  const SliderItem = (props) => (
    <Card
      key={props.key}
      style={{ marginLeft: '20px', marginRight: isMobile ? '0px' : '20px', cursor: 'pointer', width: isMobile ? '340px' : '' }}
      onClick={() => push(`/${props.data?.id}`)}
    >
      <CardImg
        style={{ height: '215px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', objectFit: 'cover' }}
        src={`${process.env.REACT_APP_SERVER_HOST}${props.data?.imagePath}`}
      />
      <CardBody
        className="p-3"
        style={{
          height: '175px',
          color: 'rgba(94,94,94,1)',
          fontWeight: 400,
          backgroundColor: 'rgba(247,244,238,1)',
          borderRadius: '6px',
          position: 'relative',
        }}
      >
        {props.isMom && (
          <div
            style={{
              height: '40px',
              width: '102px',
              backgroundColor: 'rgba(178,147,104,0.8)',
              position: 'absolute',
              top: '-40px',
              left: 0,
              textAlign: 'center',
            }}
          >
            <span style={{ color: 'white', fontSize: '18px', lineHeight: '40px' }}>{MomType[props.data?.type]}</span>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CardTitle className="news-title">{props.data?.title}</CardTitle>
        </div>
        <p className="news-brief">{props.data?.briefIntro}</p>
      </CardBody>
    </Card>
  );
  const SliderShareItem = (props) => (
    <Card
      key={props.key}
      className="p-3"
      style={{
        marginLeft: '20px',
        marginRight: isMobile ? '0px' : '20px',
        width: isMobile ? '340px' : '',
        height: 432,
        color: 'white',
        fontWeight: 400,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(20px)',
        borderRadius: '6px',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CardTitle className="news-title" style={{ color: 'white' }}>
          熱情分享
        </CardTitle>
      </div>
      <span style={{ fontSize: '16px', fontWeight: 500, marginBottom: '10px' }}>{props.data?.title}</span>
      {_.map(props.data?.content, (data, index) => (
        <p key={index} style={{ fontSize: '16px', fontWeight: 400, marginBottom: '10px', lineHeight: '28px' }}>
          {data}
        </p>
      ))}
    </Card>
  );
  const SliderArrow = ({ style, currentSlide, slideCount, isPrev, ...props }) => {
    return <img {...props} style={{ height: '38px' }} src={isPrev ? images.prev_01 : images.next_01} alt="next" />;
  };
  let sliderSettings = {
    autoplaySpeed: 5000,
    autoplay: true,
    pauseOnHover: false,
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <SliderArrow isPrev={true} />,
    nextArrow: <SliderArrow />,
  };

  const CareItemRow = (props) => (
    <Row style={{ height: '208px', width: '80%', float: props.isOne ? '' : 'right' }} className="mb-4">
      {(props.isOne ? CareListOne : CareListTwo).map((post, idx) => (
        <Col className="care-card mb-4" key={idx}>
          <Card
            small
            className="card-post card-post--aside card-post--1"
            style={{
              width: '100%',
              borderRadius: '6px',
            }}
          >
            <div className="card-post__image" style={{ backgroundImage: `url('${post.backgroundImage}')` }}></div>
            <CardBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <h5 className="card-title" style={{ fontSize: '18px', textAlign: 'center', color: 'rgba(94,94,94,1)' }}>
                {post.title}
              </h5>
              <p
                className="card-text d-inline-block mb-3"
                style={{ fontSize: '14px', color: 'rgba(94,94,94,1)', fontWeight: 400 }}
              >
                {post.body}
              </p>
              <div style={{ margin: '0 auto' }}>
                <Button
                  theme="warning"
                  style={{
                    color: 'white',
                    backgroundColor: 'rgba(174,130,69,1)',
                    borderWidth: '0px',
                    // width: '124px',
                    minWidth: '90px',
                  }}
                  onClick={() => push(post.path)}
                >
                  瞭解更多
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
  const CareItemRow2 = () =>
    _.concat(CareListOne, CareListTwo).map((post, idx) => (
      <Col lg="3" md="4" sm="12" key={idx} style={{ paddingRight: '0px' }}>
        <Card
          small
          className="card-post card-post--1"
          style={{ height: '100%', borderRadius: '6px', minHeight: 'calc(100vh - 300px)' }}
        >
          <div
            className="card-post__image"
            style={{
              backgroundImage: `url(${post.backgroundImage})`,
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
              flex: 1,
            }}
          ></div>
          <CardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <h5 className="card-title" style={{ fontSize: '18px', textAlign: 'center', color: 'rgba(94,94,94,1)' }}>
              {post.title}
            </h5>
            <p className="card-text d-inline-block mb-3" style={{ fontSize: '14px', color: 'rgba(94,94,94,1)', fontWeight: 400 }}>
              {post.body}
            </p>
            <div style={{ margin: '0 auto' }}>
              <Button
                theme="warning"
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(174,130,69,1)',
                  borderWidth: '0px',
                  width: '124px',
                }}
                onClick={() => push(post.path)}
              >
                瞭解更多
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    ));

  if (loading) {
    return (
      <Container fluid style={{ position: 'absolute', top: 0, height: '100vh', zIndex: 4000 }} css={styles}>
        <Row
          style={{
            backgroundImage: `url(${images.background_01_a})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '100%',
          }}
        >
          <Col
            style={{
              backdropFilter: 'blur(7px)',
            }}
          >
            <div style={{ width: '240px', margin: 'auto', marginTop: 'calc(50vh - 120px)', position: 'relative' }}>
              <ProgressBar
                progress={progress}
                radius={100}
                cut={180}
                rotate={180}
                strokeWidth={7}
                strokeColor={'white'}
                trackStrokeColor={'white'}
                trackStrokeWidth={1}
              />
              <div>
                <div className="indicator">
                  <img src={images.tree_loading} />
                  <span style={{ position: 'absolute', color: 'rgba(175,154,126,1)', fontSize: '16px' }}>{`${progress}%`}</span>
                </div>
                <span className="indicator-span">國泰專業團隊，時刻守護媽媽和寶寶</span>
              </div>
            </div>
          </Col>
          {!isMobile && (
            <MainFooter copyright="版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰竹教產後護理之家 | 國泰台南產後護理之家 Copyright © Cathay Healthcare Inc., Ltd. All Rights Reserved." />
          )}
        </Row>
      </Container>
    );
  }
  return (
    <div css={styles(images)} style={{ marginTop: '-90px' }}>
      <MetaTags>
        <title>國泰產後護理之家｜台北月子中心、內湖月子中心、新竹月子中心、台南月子中心推薦</title>
        <meta name="description" content="國泰產後護理之家，資深專業團隊，時刻守護媽媽和寶寶！" />
        <link rel="canonical" href="https://www.cathaybabycare.com.tw"></link>
      </MetaTags>
      <ReactPageScroller
        style={{height: 'calc(100vh - 1px)'}}
        renderAllPagesOnFirstRender={true}
        pageOnChange={(e) => handlePageChange(e)}
        // onBeforePageScroll={(e) => handleBeforePageChange(e)}
        customPageNumber={currentPage}
      >
        {/* 首頁 */}
        <Container
          fluid
          className="main-content-container p-0 home-background"
          style={{ backgroundImage: `url(${images.background_01_a})`, backgroundPosition: 'center' }}
        >
          <div className={`home-side-left ${isFirst ? 'elementToFadeIn1 delay-1' : ''}`}>
            <div
              className={`${isFirst ? 'elementToFadeIn1 delay-4' : ''}`}
              style={{ margin: isMobile ? 'auto' : '', marginLeft: isMobile ? '20px' : ''}}
            >
              <h1 className="home-title">國泰產後護理之家</h1>
              <h2 className="home-subtitle">資深專業團隊，時刻守護媽媽和寶寶！</h2>
            </div>
          </div>
          <div
            className={`${!isMobile && 'home-side-right-bottom'} ${isFirst ? 'elementToFadeIn1 delay-2' : ''}`}
            style={{ backgroundColor: 'rgba(0,0,0,0.39)' }}
          >
            <div className="home-community">
              {_.map(communityDatas, (data, index) => (
                <CommunityItem item={data} key={index} />
              ))}
            </div>
          </div>      
        </Container>

        {/* 最新消息 */}
        <Container
          fluid
          css={styles}
          className="main-content-container p-0 home-background"
          style={{ backgroundImage: `url(${images.background_01_b_h})`, position: 'relative' }}
        >
          <div className="news-half-bottom"></div>
          {isMobile ? (
            <div style={{ width: '100%', height: 'calc(100% - 200px)', overflowX: 'scroll', margin: 'auto' }} ref={refNewsScrollView}>
              <div style={{ width: `${340 * newsList?.length}px`, height: '100%', display: 'flex', marginTop: '80px' }}>
                {_.map(newsList, (data, index) => (
                  <SliderItem key={index} data={data} />
                ))}
              </div>
              <img style={{position: 'absolute', zIndex: '20', right: 20, top: '50%', height: '38px'}} 
          src={images.next_grey} onClick={handleNewsArrowClick} />
            </div>
          ) : (
            <Slider {...sliderSettings} infinite={false} slidesToShow={_.min([newsList?.length, 3])} className="news-container">
              {_.map(newsList, (data, index) => (
                <SliderItem key={index} data={data} />
              ))}
            </Slider>
          )}
        </Container>

        {/* 專業照顧 */}
        <Container
          fluid
          css={styles}
          className="main-content-container p-0 home-background"
          style={{ backgroundImage: `url(${images.background_01_c})`, position: 'relative'}}
        >
          {isMobile ? (
            <div style={{ width: '100%', height: 'calc(100% - 200px)', overflowX: 'scroll', margin: 'auto'}} ref={refCareScrollView} >
              <div
                style={{
                  width: `${240 * 4}px`,
                  height: '100%',
                  display: 'flex',
                  marginTop: '40px',
                }}
              >
                <CareItemRow2 />
              </div>
              <img style={{position: 'absolute', zIndex: '20', right: 20, top: '50%', height: '38px'}} 
          src={images.next_grey} onClick={handleCareArrowClick} />
            </div>
          ) : (
            <div className="care-container">
              <CareItemRow isOne={true} />
              <CareItemRow isOne={false} />
            </div>
          )}
        </Container>

        {/* 婦幼專區 */}
        <Container
          fluid
          css={styles}
          className="main-content-container p-0 home-background"
          style={{ backgroundImage: `url(${images.background_01_d})`, position: 'relative' }}
        >
          <div className="half-left"></div>
          {isMobile ? (
            <div style={{ width: '100%', height: 'calc(100% - 200px)', overflowX: 'scroll', margin: 'auto' }} ref={refMomScrollView}>
              <div style={{ width: `${340 * momList?.length}px`, height: '100%', display: 'flex', marginTop: '80px' }}>
                {_.map(momList, (data, index) => (
                  <SliderItem key={index} data={data} isMom={true} />
                ))}
              </div>
              <img style={{position: 'absolute', zIndex: '20', right: 20, top: '50%', height: '38px'}} 
          src={images.next_grey} onClick={handleMomArrowClick} />
            </div>
          ) : (
            <Slider {...sliderSettings} slidesToShow={_.min([momList?.length, 3])} className="news-container">
              {_.map(momList, (data, index) => (
                <SliderItem key={index} data={data} isMom={true} />
              ))}
            </Slider>
          )}
        </Container>

        {/* 全方位親子照護規劃 */}
        <AllRoundContainer />

        {/* 熱情分享 */}
        {/* <Container
          fluid
          css={styles}
          className="main-content-container p-0 home-background"
          style={{ backgroundImage: `url(${images.background_01_f})`, position: 'relative' }}
        >
          {isMobile ? (
            <div style={{ width: '100%', height: 'calc(100% - 200px)', overflowX: 'scroll', margin: 'auto' }}>
              <div style={{ width: `${340 * momList?.length}px`, height: '100%', display: 'flex', marginTop: '80px' }}>
                {_.map(ShareList, (data, index) => (
                  <SliderShareItem key={index} data={data} />
                ))}
              </div>
            </div>
          ) : (
            <Slider {...sliderSettings} className="news-container">
              {_.map(ShareList, (data, index) => (
                <SliderShareItem key={index} data={data} />
              ))}
            </Slider>
          )}
        </Container> */}
      </ReactPageScroller>

      {detail && !loading2 && (
        <ListPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => void (draft.detail = null));
            push('/');
          }}
          detail={{...detail, currentPage}}
        />
      )}
      <div className={`w-50 page-bar ${isFirst ? 'elementToFadeIn1 delay-3' : ''}`}>
        <span>{`PAGE${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}0${currentPage + 1}`}</span>
        <div>
          <div
            className="page-bar-filter"
            style={{
              width: `${((currentPage + 1) / 5) * 100}%`,
            }}
          ></div>
        </div>
      </div>
      <div className={`bottom-scroll ${isFirst ? 'elementToFadeIn1 delay-3' : ''}`}>
        <span>{currentPage == 4 ? '返回頁首' : '滑動向下'}</span>
        <img src={currentPage == 4 ? images.prev_page : images.next_page} 
          style={currentPage == 4 ? {cursor:'pointer'} : {}}
        onClick={() => goTopPage()}/>
      </div>
      {loading2 && (
        <div className="loading-view">
          <BounceLoader size={150} color={'#52B54F'} loading={loading2} />
        </div>
      )}
      <PrivacyPopUp
        isShowDialog={isPrivacy}
        overlayAction={() => {
          produce((draft) => {
            draft.isPrivacy = false;
          });
        }}
      />
      {!isMobile && (
        <MainFooter
          className={`${isFirst ? 'elementToFadeIn1 delay-3' : ''} ${currentPage == 4 ? 'mb-3' : ''}`}
          copyright={
            currentPage == 4 ? (
              <span>
                國泰台北會館產後護理之家 / 02-2748-5111 / 台北市松山區健康路156號7樓 ・ 
                國泰內湖產後護理之家 / 02-2658-5999 / 台北市內湖區瑞光路337號11-13樓 {'\n'}
                國泰竹教產後護理之家 / 03-515-2669 / 新竹市北區武陵路189號6.7樓・
                國泰台南產後護理之家 / 06-215-0599 / 台南市中西區永福路一段185號3樓{'\n'}服務信箱：
                <a style={{ color: 'white', textDecoration: 'none' }} href="mailto:cathaybabyservice@cathay-hcm.com.tw">
                  cathaybabyservice@cathay-hcm.com.tw{' '}
                </a>
                /{' '}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    produce((draft) => {
                      draft.isPrivacy = true;
                    });
                  }}
                >
                  隱私權政策
                </span>{' '}
                / 版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰竹教產後護理之家 | 國泰台南產後護理之家 {'\n'}Copyright © Cathay Healthcare Inc., Ltd. All
                Rights Reserved.
              </span>
            ) : (
              '版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰竹教產後護理之家 | 國泰台南產後護理之家 Copyright © Cathay Healthcare Inc., Ltd. All Rights Reserved.'
            )
          }
        />
      )}
    </div>
  );
};

export default Home;

const styles = (images) => css`
  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 80%;
    margin: 0 auto;
    font-size: 20px;
    color: white;
    user-select: none;
  }
  .indicator-span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    height: 125%;
    width: 100%;
    margin: 0 auto;
    color: white;
    user-select: none;
  }

  .home-title {
    margin-bottom: 0px;
    color: white;
    font-size: 36px;
    letter-spacing: 0;
  }
  .home-subtitle {
    color: white;
    font-size: 16px;
    letter-spacing: 0;
  }
  .home-background {
    z-index: 1400;
    background-size: cover;
    min-height: 100vh;
    display: flex;
  }
  .home-side-left {
    width: 50%;
    margin-left: 0px;
    margin-right: auto;
    height: 100vh;
    background-image: url(${images.img_01_a});
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(6px);
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .home-side-left div {
    display: flex;
    flex-flow: column;
    margin-left: 120px;
    justify-content: center;
    height: 100%;
  }
  .home-side-right-bottom {
    width: 50%;
    margin-right: 0px;
    margin-left: auto;
    height: calc(50vh - 45px);
    margin-bottom: 0px;
    margin-top: auto;
  }
  .home-community {
    width: 230px;
    height: 282.5px;
    float: right;
    margin-top: calc((50vh - 282.5px) / 2);
    border-left: 1px solid white;
    @media (max-width: 992px) {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 35%;
    }
  }
  .home-community-icon {
    opacity: 0.6;
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 15px;
    width: 80px;
  }
  .home-community-icon:hover {
    opacity: 1;
  }
  .home-community-icon span {
    opacity: 0.6;
    color: white;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
  }

  .news-container {
    height: 390px;
    width: calc(100% - 240px);
    margin: auto;
  }
  .news-half-bottom {
    position: absolute;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.39);
    width: 100%;
    height: 50vh;
  }
  .news-title {
    color: rgba(94, 94, 94, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .news-brief {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .care-container {
    height: 446px;
    width: calc(100% - 340px);
    margin: auto;
  }
  .care-card {
    display: flex;
    width: 40%;
    height: 100%;
  }

  .half-left {
    position: absolute;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.39);
    width: 50%;
    height: 100vh;
  }

  .page-bar {
    height: 40px;
    position: absolute;
    bottom: 70px;
    @media (max-width: 992px) {
      bottom: 33px;
    }
  }
  .page-bar-filter {
    height: 100%;
    background-color: rgba(82, 181, 79, 1);
  }
  .page-bar span {
    margin-left: 120px;
    color: white;
    font-size: 16px;
    @media (max-width: 992px) {
      margin-left: 20px;
    }
  }
  .page-bar > div {
    background-color: rgba(255, 255, 255, 0.2);
    height: 6px;
    margin-left: 120px;
    margin-right: 168px;
    margin-top: 6px;
    bottom: 70px;
    @media (max-width: 992px) {
      margin-left: 20px;
      width: calc(100% - 76px);
    }
  }
  .bottom-scroll {
    display: flex;
    flex-direction: column;
    left: calc(50% - 28px);
    position: absolute;
    bottom: 70px;
    align-items: center;
    @media (max-width: 992px) {
      bottom: 33px;
    }
  }
  .bottom-scroll span {
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .bottom-scroll img {
    width: 40px;
    height: 40px;
  }

  .elementToFadeIn1 {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;  /* 呼叫命名為 fadeIn 的 keyframe, 使用 ease-in 動畫效果並且只重複執行一次 */
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;  /* 確保在動畫完成後，我們會保持在最後的 keyframe 值 (opacity: 1)*/
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;
  }

  @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

  .loading-view {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .loading-view span {
    margin-left: calc((50vw - 75px) / 2);
    margin-top: calc(50vh - 75px);
  }
  .delay-1 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
  }
  .delay-2 {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-delay: 2s;
  }
  .delay-3 {
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    animation-delay: 3s;
  }
  .delay-4 {
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    animation-delay: 4s;
  }
`;
