import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar, NavbarBrand } from 'shards-react';
import { useMediaQuery } from 'react-responsive';

import NavbarNav from './NavbarNav';
import NavbarToggle from './NavbarToggle';
import NavbarItems from './NavbarItems';
import SlideDrawer from '../../common/SlideDrawer';
import SliderDrawerMobile from '../../custom-list/SliderDrawerMobile';

import useRouter from '../../../hooks/use-router';
import { LAYOUT_TYPES } from '../../../utils/constants';
import images from '../../../images/images';

const MainNavbar = ({ layout, stickyTop }) => {
  const { push, pathname } = useRouter();
  const isHeaderNav = layout === LAYOUT_TYPES.HEADER_NAVIGATION;
  const classes = classNames('main-navbar', stickyTop && 'sticky-top');
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 402px)' });

  return (
    <div className={classes} style={{ backgroundColor: 'rgba(0, 0, 0, 0.39)', zIndex: 1500, marginLeft: '-1px' }}>
      <Container fluid={!isHeaderNav || null} className="p-0" style={{height: isSmallMobile ? 60 : 90}}>
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <NavbarToggle />
          <NavbarBrand className={`${isMobile ? 'w-100' : 'w-50'} mr-0`} 
          style={{ lineHeight: '25px', alignItems: 'center', height: isSmallMobile ? 60 : 90}}>
            <div className="d-table" style={{ marginLeft: '120px', margin: 'auto' }}>
              <img
                id="main-logo"
                className="d-inline-block align-middle mr-1"
                style={{ maxWidth: '264px', cursor: 'pointer', width:'60vw' }}
                onClick={() => push('/')}
                src={images.logo}
                alt="Cathay Postpartum Nusring Care Center"
              />
            </div>
          </NavbarBrand>
          {!isMobile && (
            <NavbarBrand className="w-50 mr-0" style={{ lineHeight: '25px', alignItems: 'center', cursor: 'pointer' }}>
              <NavbarItems />
            </NavbarBrand>
          )}
          {isMobile ? <SliderDrawerMobile show={!pathname.includes('/book')} /> : <SlideDrawer show={!pathname.includes('/book')} />}
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
