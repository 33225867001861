import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { NavLink as _RouteNavLink } from 'react-router-dom';
import { NavItem, NavLink, DropdownMenu, DropdownItem, Collapse } from 'shards-react';

import useLocalState from '../../../hooks/use-local-state';
import useRouter from '../../../hooks/use-router';

const RouteNavLink = (props) => <_RouteNavLink {...props} />;

const NavbarItem = ({ item }) => {
  const { toggleSidebarDropdown } = useLocalState();
  const [hover, setHover] = useState(false);
  const { history, location } = useRouter();

  const hasSubItems = item.items && item.items.length;
  var isActive = String(location.pathname).includes(String(item.to));

  return (
    <NavItem css={styles(item, isActive)} style={{ position: 'relative' }}>
      <NavLink
        active={isActive}
        style={{ display: 'flex' }}
        activeClassName="custom-active"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={hasSubItems && 'dropdown-toggle'}
        tag={hasSubItems ? 'a' : RouteNavLink}
        to={{
          pathname: hasSubItems ? '#' : item.to,
          state: { subtitle: item.subtitle },
        }}
        onClick={() => {
          toggleSidebarDropdown(item);
        }}
      >
        {item.title && <span style={{ color: 'white', margin: 'auto' }}>{item.title}</span>}
      </NavLink>
      {hasSubItems && (
        <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
          {item.items?.map((subItem, idx) => (
            <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
              {subItem.title}
            </DropdownItem>
          ))}
        </Collapse>
      )}
    </NavItem>
  );
};

NavbarItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
};

const styles = (item, hover) => css`
   {
    .iconImage {
      height: 26px;
      width: 26px;
      background-size: contain;
    }

    .custom-active span {
      color: var(--main-color);
    }
    .custom-active {
      box-shadow: inset 0.1875rem 0 0 var(--main-color);
      color: var(--main-color);
    }
  }
`;

export default NavbarItem;
