import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as _RouteNavLink } from 'react-router-dom';
import { NavItem, NavLink, DropdownMenu, DropdownItem, Collapse } from 'shards-react';

import useLocalState from '../../../hooks/use-local-state';

const RouteNavLink = (props) => <_RouteNavLink {...props} />;

const SidebarNavItem = ({ item }) => {
  const { toggleSidebarDropdown, toggleSidebar } = useLocalState();

  const hasSubItems = item.items && item.items?.length;

  return (
    <NavItem style={{ position: 'relative' }}>
      <NavLink
        className={hasSubItems && 'dropdown-toggle'}
        tag={hasSubItems ? 'a' : RouteNavLink}
        to={hasSubItems ? '#' : item.to}
        onClick={() => toggleSidebarDropdown(item)}
      >
        {item.htmlBefore && (
          <div className="d-inline-block item-icon-wrapper" dangerouslySetInnerHTML={{ __html: item.htmlBefore }} />
        )}
        {item.title && <span style={{ fontWeight: 300 }}>{item.title}</span>}
        {item.htmlAfter && (
          <div className="d-inline-block item-icon-wrapper" dangerouslySetInnerHTML={{ __html: item.htmlAfter }} />
        )}
      </NavLink>
      {hasSubItems && (
        <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0, backgroundColor: 'rgba(247,244,238,1)' }}>
          {item.items?.map((subItem, idx) => (
              <DropdownItem key={idx} onClick={toggleSidebar} style={{ backgroundColor: 'rgba(247,244,238,1)' }}>
                <a href={subItem.to} target={String(subItem.to).startsWith("http") && "_blank"} style={{ color: '#3d5170' }}>{subItem.title}</a>             
              </DropdownItem>      
          ))}
        </Collapse>
      )}
    </NavItem>
  );
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
};

export default SidebarNavItem;
