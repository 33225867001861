import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'shards-react';
import { css } from '@emotion/core';
import ReactShadowScroll from 'react-shadow-scroll';

import images from '../../images/images';
import _ from 'lodash';

const PrivacyPopUp = ({ overlayAction, isShowDialog = false }) => {
  const privacyContent = [
    {
      key: 'content',
      value:
        '非常歡迎您光臨國泰產後護理之家網站！本公司在此聲明尊重並依法保護您個人的隱私權。為了讓您能夠更安心的使用本網站所提供的各項服務，並透明化本網站如何蒐集、應用及保護您所提供的個人資料，特此向您說明本網站的隱私權保護政策如下：',
    },
    { key: 'title', value: '個人資料之蒐集政策' },
    {
      key: 'content',
      value:
        '當您參與線上活動、網路調查或申請相關服務時，我們可能會請您提供姓名、身分證字號、住址、電話、e-mail或其他相關資料。除此之外，我們也會保留您在上網瀏覽或查詢時，伺服器自行產生的相關紀錄，包括但不限於您使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選資料紀錄等。請您注意，與本網站連結的其他網站，也可能蒐集您個人的資料，對於您主動提供其他網站的個人資料，這些連結網站有其各自的隱私權保護政策，本網站隱私權保護政策不適用於其資料處理措施。',
    },
    {
      key: 'title',
      value: '本網站上述蒐集資料之運用政策',
    },
    {
      key: 'content',
      value:
        '本網站所蒐集的個人資料，將依其蒐集時所闡述之特定目的使用，線上申請服務中所涉及的個人資訊將會被轉交給負責該項服務的相關單位，以便與您聯繫。針對各類線上活動，該個人資料將會視蒐集目的做為答覆問題及抽獎結果的通知、商業及市場的分析…等之用。個人資料之使用目的、利用對象、時間等資訊請參考活動網頁之內容，本網站不會將其做為超出蒐集之特定目的以外的用途。',
    },
    {
      key: 'title',
      value: 'Cookies的運用與政策',
    },
    {
      key: 'content',
      value:
        'Cookies是伺服端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些簡短資訊。您可以在瀏覽器設定中選擇修改您瀏覽器對Cookies的接受程度，包括接受所有cookies、設定cookies時得到通知、拒絕所有cookies等三種。如果您選擇拒絕所有的cookies，您就可能無法使用部分個人化服務，或是參與部分的活動。為了提供您更好、更個人化的服務，以及方便您參與個人化的互動活動。Cookies 在您註冊或登入時建立，並在您登出時修改。另外，為了統計瀏覽人數及分析瀏覽模式，以瞭解網頁瀏覽的情況，做為本網站改善服務的參考，本網站會在本政策原則下，在您瀏覽器中寫入並讀取Cookies。',
    },
    {
      key: 'title',
      value: '本網站與第三者共用個人資料之政策',
    },
    {
      key: 'content',
      value:
        '本網站絕對不會任意出售、交換、出租或以其他變相之方式，將您的個人資料揭露與其他團體或個人。惟有下列三種情形，本網站會與第三人共用您的個人資料：',
      list: [
        '•經過您的事前同意或授權允許時。',
        '司法單位或其他主管機關經合法正式的程序要求時。',
        '為了提供您其他服務或優惠權益，需要與提供該服務或優惠之第三人共用您的資料時，本網站會在活動時提供充分說明並告知，您可以自由選擇是否接受這項服務或優惠。',
      ],
    },
    {
      key: 'title',
      value: '本網站傳送商業資訊或電子郵件之政策',
    },
    {
      key: 'content',
      value:
        '為了增加整體服務內容，本網站所傳送的內容將包含非本行的服務或其他優惠權益訊息，我們將會在活動時提供充分的說明，您可以自由選擇是否接受這項特定服務或活動，若您選擇接受本項服務，本網站保證不會將您在本網站或本行所登錄的任何資料轉送給第三人。本網站將在事前或註冊登錄取得您的同意後，傳送商業性資料或電子郵件給您。本網站除了在該資料或電子郵件上註明是由我們發送，也會在該資料或電子郵件上提供您能隨時停止接收這些資料或電子郵件的方法、說明或功能連結。',
    },
    {
      key: 'title',
      value: '自我保護措施',
    },
    {
      key: 'content',
      value:
        '本網站使用SSL（Secure Socket Layer）加密技術以保護您所提供的資訊，以免在傳輸時被其他第三人加以截取。本行努力提供安全的資訊環境，建立防火牆、加密技術、使用者辨識系統（例如密碼及個人帳號）及存取控制機制，以控制個人資料之存取。請妥善保管您的任何個人資料、CA憑證或密碼，不要將任何個人資料，尤其是密碼提供給任何人或其他機構。在您使用完本網站所提供的各項服務功能後，務必記得登出，若您是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料或信件。',
    },
    {
      key: 'title',
      value: '網站隱私權政策的修改',
    },
    {
      key: 'content',
      value:
        '本公司將會視需要隨時修改本網站所提供的隱私權保護聲明，以落實保障您的隱私權。建議您於再次來訪時，可重新瞭解我們新的隱私權政策及其改變。',
    },
    {
      key: 'title',
      value: '消費者聯絡資訊',
    },
    {
      key: 'content',
      value:
        '本公司將會視需要隨時修改本網站所提供的隱私權保護聲明，以落實保障您的隱私權。建議您於再次來訪時，可重新瞭解我們新的隱私權政策及其改變。',
    },
  ];
  return (
    <div css={stylesModal}>
      <Modal
        css={styles}
        centered
        open={isShowDialog}
        toggle={() => overlayAction && overlayAction()}
        backdropClassName="backdropBackground"
      >
        <ModalBody>
          <Col>
            <Row style={{ marginBottom: 20 }}>
              <label className="modal-title">隱私權政策</label>
              <span className="btn" onClick={() => overlayAction && overlayAction()}>
                <img className="d-table mx-auto mb-3" src={images.close} alt="close" />
              </span>
            </Row>
            <Row style={{ background: '#C5C1BA', height: 1, marginRight: 25 }} />
            <Row>
              <ReactShadowScroll
                isShadow={false}
                scrollColor="#52B54F"
                style={{
                  height: 'calc(50vh)',
                  marginBottom: 20,
                  marginTop: 20,
                  padding: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: '100%',
                }}
              >
                {_.map(privacyContent, (item, index) => {
                  return (
                    <div key={index} style={{ marginBottom: 15 }}>
                      {item.key == 'content' ? (
                        <span style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>{item.value}</span>
                      ) : (
                        <span style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 500 }}>{item.value}</span>
                      )}
                    </div>
                  );
                })}
              </ReactShadowScroll>
            </Row>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PrivacyPopUp;

const stylesModal = css`
  .modal {
    z-index: 3000;
  }
`;
const styles = css`
  min-width: 70%;

  .element {
    width: fit-content;
    background: white;
    width: 100%;
    max-width: calc(100vw - 60px);
    margin-bottom: 40px;
  }
  .element img {
    width: 100%;
  }

  .modal-dialog {
    margin: auto;
  }
  .modal-header {
    border: none;
    font-family: PingFangTC-Medium;
    font-size: 13px;
    color: #505050;
    text-align: justify;
  }
  .modal-content {
    margin-top: 40px;
    max-height: calc(100vh - 80px);
    background: #f7f4ee;
    border-radius: 6px;
  }
  span.btn {
    position: absolute;
    top: -12px;
    right: -20px;
  }
  span.btn img {
    width: 15px;
  }
  .modal-title {
    font-family: PingFangTC-Medium;
    font-size: 24px;
    color: #5e5e5e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .create-date {
    font-family: PingFangTC-Regular;
    font-size: 14px;
    color: #5e5e5e;
    text-align: justify;
    font-weight: 400;
  }
  .share-row {
    justify-content: space-between;
    padding-right: 25px;
  }
  .share-row img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    cursor: pointer;
  }
  .subcontainer {
    padding-right: 30px !important;
  }
`;
