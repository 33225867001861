import React from 'react';
import { useMediaQuery } from 'react-responsive';
import useLocalState from '../../../hooks/use-local-state';

const NavbarToggle = (props) => {
  const { toggleSidebar } = useLocalState();
  const isSmallMobile = useMediaQuery({ query: '(max-width: 402px)' });

  return (
    <nav className="nav" style={{ position: 'absolute', height: isSmallMobile ? 60 : 90 }}>
      <a
        href="#"
        onClick={toggleSidebar}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
        style={{ alignSelf: 'center' }}
      >
        <i className="material-icons" style={{ color: 'white' }}>
          &#xE5D2;
        </i>
      </a>
    </nav>
  );
};

export default NavbarToggle;
