import _ from 'lodash';
import useApi, { API_METHOD } from '../hooks/use-api';
import { RESERVE_INFO_CHECK, RESERVE_INFO } from './API';

const useReserve = () => {
  const { FetchApi } = useApi();

  const postReserveInfoCheck = async (data) => {
    const { status, message, result } = await FetchApi(API_METHOD.POST, RESERVE_INFO_CHECK, data, false);
    return { status, message, result };
  };

  const postReserveInfo = async (data) => {
    const { status, message, result } = await FetchApi(API_METHOD.POST, RESERVE_INFO, data, false);
    return { status, message, result };
  };

  const getMsgSource = async (data) => {
    const queryPath = `${RESERVE_INFO}/InfoFromOptions?branch=${data}`
    const { status, message, result } = await FetchApi(API_METHOD.GET, queryPath, data, false);
    return { status, message, result };
  };

  return {
    postReserveInfoCheck,
    postReserveInfo,
    getMsgSource
  };
};

export default useReserve;
