export default () => [
  {
    title: '關於國泰產後',
    to: '/about-postpartum',
  },
  {
    title: '會館介紹',
    items: [
      {
        title: '國泰台北會館產後護理之家',
        to: '/taipei-hall',
      },
      {
        title: '國泰內湖產後護理之家',
        to: '/neihu-hall',
      },
      {
        title: '國泰竹教產後護理之家',
        to: '/hsinchu-hall',
      },
      {
        title: '國泰台南產後護理之家',
        to: '/tainan-hall',
      },
    ],
  },
  {
    title: '專業照護',
    items: [
      {
        title: '醫護陣容',
        to: '/medical-lineup',
      },
      {
        title: '營養膳食',
        to: '/diet',
      },
      {
        title: '媽媽照護',
        to: '/mother',
      },
      {
        title: '寶寶呵護',
        to: '/baby',
      },
    ],
  },
  {
    title: '婦幼專區',
    items: [
      {
        title: '媽媽教室',
        to: '/mom-classrooms',
      },
      {
        title: '母嬰資訊',
        to: '/mom-baby-infos',
      },
      {
        title: '暖心紀錄',
        to: '/warm-records',
      },
    ],
  },
  {
    title: '最新消息',
    to: '/news',
  },
  {
    title: '訂閱電子報',
    to: 'http://news.cathay-hcm.com.tw/index.php/latest_newsletter_babycare.html',
  },
  {
    title: '預約參觀',
    to: '/book',
  }
];
