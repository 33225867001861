export default {
  //Loading
  tree_loading: require('./tree_loading/tree_loading.png'),
  //Home
  background_01_a: require('./background/01_a_photo.png'),
  img_01_a: require('./background/01_a.png'),
  logo: require('./logo/logo.png'),
  facebook: require('./facebook/facebook.png'),
  ig: require('./ig/ig.png'),
  youtube: require('./youtube/youtube.png'),
  line: require('./line/line.png'),
  drop_down: require('./drop_down/drop_down.png'),
  next_page: require('./next_page/next_page.png'),
  prev_page: require('./prev_page/prev_page.png'),
  reservation_close: require('./reservation_close/reservation_close.png'),
  reservation_open: require('./reservation_open/reservation_open.png'),
  calendar: require('./calendar/calendar.png'),
  //Home_News
  background_01_b_h: require('./background/01_b_photo.png'),
  next_01: require('./next_01/next_01.png'),
  prev_01: require('./prev_01/prev_01.png'),
  next_grey: require('./next_01/next_grey.png'),
  prev_grey: require('./prev_01/prev_grey.png'),
  //News
  background_01_b: require('./12_a/12_a_background.png'),
  close: require('./close/close.png'),
  share_01: require('./share/share_01@2x.png'),
  share_02: require('./share/share_02@2x.png'),
  share_03: require('./share/share_03@2x.png'),
  share_04: require('./share/share_04@2x.png'),
  //專業照顧
  background_01_c: require('./background/01_c_photo.png'),
  care_01: require('./01_c/01.png'),
  care_02: require('./01_c/02.png'),
  care_03: require('./01_c/03.png'),
  care_04: require('./01_c/04.png'),
  care_05: require('./01_c/05.jpg'),
  care_06: require('./01_c/06.jpg'),
  //婦幼專區
  background_01_d: require('./background/01_d_photo.png'),
  //全方位照顧
  background_01_e: require('./background/01_e_photo.png'),
  day_01: require('./01_e/day_01.png'),
  day_02: require('./01_e/day_02.png'),
  day_03: require('./01_e/day_03.png'),
  day_04: require('./01_e/day_04.png'),
  day_05: require('./01_e/day_05.png'),
  day_06: require('./01_e/day_06.png'),
  day_07: require('./01_e/day_07.png'),
  //熱情分享
  background_01_f: require('./background/01_f_photo.png'),
  //醫護陣容
  background_06_b_02: require('./06_b/02@2x.png'),
  background_04_a: require('./background/04_a_background.png'),
  top_04_a: require('./background/04_a.png'),
  doctor_01: require('./doctor/doctor_01.png'),
  doctor_02: require('./doctor/doctor_02.png'),
  doctor_03: require('./doctor/doctor_03.png'),
  //關於國泰產後
  about_04_a_01: require('./04_a/01.png'),
  about_04_a_02: require('./04_a/02.png'),
  about_04_a_03: require('./04_a/03.png'),
  about_04_a_04: require('./04_a/04.png'),
  about_04_a_05: require('./04_a/05.png'),
  about_04_a_06: require('./04_a/06.png'),
  about_04_a_07: require('./04_a/07.png'),
  about_04_a_08: require('./04_a/08.png'),
  about_04_a_09: require('./04_a/09.png'),
  about_04_a_10: require('./04_a/10.png'),
  about_04_a_11: require('./04_a/11.png'),
  about_04_a_12: require('./04_a/12.png'),
  tree_icon: require('./tree_icon/dot_icon.png'),
  line_icon: require('./tree_icon/line_icon.png'),
  //營養膳食
  background_08: require('./background/08_background.png'),
  top_08: require('./background/08.png'),
  diet_08_01: require('./08/01.png'),
  diet_08_02: require('./08/02.png'),
  diet_08_03: require('./08/03.png'),
  diet_08_04: require('./08/04.png'),
  //媽媽照護
  background_09: require('./background/09_background.png'),
  top_09: require('./background/09.png'),
  mother_09_01: require('./09/01.png'),
  mother_09_02: require('./09/02.png'),
  mother_09_03: require('./09/03.png'),
  mother_09_04: require('./09/04.png'),
  mother_09_05: require('./09/05.png'),
  mother_09_06: require('./09/06.png'),
  //寶寶呵護
  background_10: require('./background/10_background.png'),
  top_10: require('./background/10.png'),
  baby_10_01: require('./10/01.png'),
  baby_10_02: require('./10/02.png'),
  baby_10_03: require('./10/03.png'),
  baby_10_04: require('./10/04.png'),
  baby_10_05: require('./10/05.png'),
  baby_10_06: require('./10/06.png'),
  baby_10_07: require('./10/07.png'),
  baby_10_08: require('./10/08.png'),
  baby_10_09: require('./10/09.png'),
  baby_10_10: require('./10/10.png'),
  baby_10_11: require('./10/11.png'),
  //國泰台北會館公共區域
  background_05_a: require('./background/05_a_background.png'),
  top_05_a: require('./background/05_a.png'),
  taipei_05_a_01: require('./05_a/01.png'),
  taipei_05_a_02: require('./05_a/02.png'),
  taipei_05_a_03: require('./05_a/03.png'),
  taipei_05_a_04: require('./05_a/04.png'),
  taipei_05_a_05: require('./05_a/05.png'),
  taipei_05_a_06: require('./05_a/06.png'),
  taipei_05_a_07: require('./05_a/07.png'),
  taipei_05_a_08: require('./05_a/08.png'),
  taipei_05_a_09: require('./05_a/09.jpg'),
  taipei_05_a_10: require('./05_a/10.jpg'),
  taipei_05_a_11: require('./05_a/11.jpg'),
  //國泰台北會館房型介紹
  taipei_05_b_01: require('./05_b/01.jpg'),
  taipei_05_b_02: require('./05_b/02.jpg'),
  taipei_05_b_02b: require('./05_b/02b.jpg'),
  taipei_05_b_03: require('./05_b/03.png'),
  taipei_05_b_04: require('./05_b/04.png'),
  taipei_05_b_05: require('./05_b/05.png'),
  taipei_05_b_06: require('./05_b/06.png'),
  square_feet: require('./square_feet/square_feet.png'),
  //國泰台北會館房型介紹詳細
  taipei_room_01_01: require('./taipei_room/01/01.jpg'),
  taipei_room_01_02: require('./taipei_room/01/02.jpg'),
  taipei_room_01_03: require('./taipei_room/01/03.jpg'),
  taipei_room_01_04: require('./taipei_room/01/04.jpg'),
  taipei_room_01_05: require('./taipei_room/01/05.jpg'),

  taipei_room_02_01: require('./taipei_room/02/01.jpg'),
  taipei_room_02_02: require('./taipei_room/02/02.jpg'),
  taipei_room_02_03: require('./taipei_room/02/03.jpg'),
  taipei_room_02_04: require('./taipei_room/02/04.jpg'),

  taipei_room_02b_01: require('./taipei_room/02b/01.jpg'),
  taipei_room_02b_02: require('./taipei_room/02b/02.jpg'),
  taipei_room_02b_03: require('./taipei_room/02b/03.jpg'),
  taipei_room_02b_04: require('./taipei_room/02b/04.jpg'),
  
  taipei_room_03_01: require('./taipei_room/03/01.jpg'),
  taipei_room_03_02: require('./taipei_room/03/02.jpg'),
  taipei_room_03_03: require('./taipei_room/03/03.jpg'),
  taipei_room_03_04: require('./taipei_room/03/04.jpg'),
  
  taipei_room_04_01: require('./taipei_room/04/01.jpg'),
  taipei_room_04_02: require('./taipei_room/04/02.jpg'),
  
  taipei_room_05_01: require('./taipei_room/05/01.jpg'),
  taipei_room_05_02: require('./taipei_room/05/02.jpg'),
  taipei_room_05_03: require('./taipei_room/05/03.jpg'),
  taipei_room_05_04: require('./taipei_room/05/04.jpg'),
  next_g_01: require('./next_01/next_g_02.png'),
  prev_g_01: require('./prev_01/next_g_01.png'),
  //國泰台南產後護理之家公共區域
  background_06_a: require('./background/06_a_background.png'),
  tainan_06_a_01: require('./06_a/01.png'),
  tainan_06_a_02: require('./06_a/02.jpg'),
  tainan_06_a_03: require('./06_a/03.jpg'),
  tainan_06_a_04: require('./06_a/04.png'),
  tainan_06_a_05: require('./06_a/05.jpg'),
  tainan_06_a_06: require('./06_a/06.jpg'),
  tainan_06_a_07: require('./06_a/07.png'),
  tainan_06_a_08: require('./06_a/08.png'),
  tainan_06_a_09: require('./06_a/09.jpg'),
  tainan_06_a_10: require('./06_a/10.jpg'),
  tainan_06_a_11: require('./06_a/11.png'),
  tainan_06_a_12: require('./06_a/12.jpg'),
  tainan_06_a_13: require('./06_a/13.jpg'),

  //國泰台南房型介紹
  tainan_06_b_01: require('./06_b/01.jpg'),
  tainan_06_b_02: require('./06_b/02.jpg'),
  tainan_06_b_03: require('./06_b/03.jpg'),
  //國泰台南房型介紹詳細
  tainan_room_01_01: require('./tainan_room/01/01.jpg'),
  tainan_room_01_02: require('./tainan_room/01/02.jpg'),
  tainan_room_01_03: require('./tainan_room/01/03.jpg'),
  tainan_room_01_04: require('./tainan_room/01/04.jpg'),
  tainan_room_01_05: require('./tainan_room/01/05.jpg'),
  tainan_room_01_06: require('./tainan_room/01/06.jpg'),
  tainan_room_01_07: require('./tainan_room/01/07.jpg'),
  tainan_room_01_08: require('./tainan_room/01/08.jpg'),
  tainan_room_01_09: require('./tainan_room/01/09.jpg'),
  
  tainan_room_02_01: require('./tainan_room/02/01.jpg'),
  tainan_room_02_02: require('./tainan_room/02/02.jpg'),
  tainan_room_02_03: require('./tainan_room/02/03.jpg'),
  tainan_room_02_04: require('./tainan_room/02/04.jpg'),
  tainan_room_02_05: require('./tainan_room/02/05.jpg'),
  
  tainan_room_03_01: require('./tainan_room/03/01.jpg'),
  tainan_room_03_02: require('./tainan_room/03/02.jpg'),
  tainan_room_03_03: require('./tainan_room/03/03.jpg'),
  tainan_room_03_04: require('./tainan_room/03/04.jpg'),
  tainan_room_03_05: require('./tainan_room/03/05.jpg'),

  //國泰內湖產後護理之家公共區域
  background_06_a: require('./background/06_a_background.png'),
  neihu_07_a_01: require('./07_a/01.png'),
  neihu_07_a_02: require('./07_a/02.png'),
  neihu_07_a_03: require('./07_a/03.png'),
  neihu_07_a_04: require('./07_a/04.png'),
  neihu_07_a_05: require('./07_a/05.png'),
  neihu_07_a_06: require('./07_a/06.png'),
  neihu_07_a_07: require('./07_a/07.png'),
  neihu_07_a_08: require('./07_a/08.png'),
  //國泰內湖房型介紹
  neihu_07_b_01: require('./07_b/01.png'),
  neihu_07_b_02: require('./07_b/02.png'),
  neihu_07_b_03: require('./07_b/03.png'),
  //國泰內湖房型介紹詳細
  neihu_room_01_01: require('./neihu_room/01/01.jpg'),
  neihu_room_01_02: require('./neihu_room/01/02.jpg'),
  neihu_room_01_03: require('./neihu_room/01/03.jpg'),
  neihu_room_01_04: require('./neihu_room/01/04.jpg'),
  neihu_room_01_05: require('./neihu_room/01/05.jpg'),
  
  neihu_room_02_01: require('./neihu_room/02/01.jpg'),
  neihu_room_02_02: require('./neihu_room/02/02.jpg'),
  neihu_room_02_03: require('./neihu_room/02/03.jpg'),
  neihu_room_02_04: require('./neihu_room/02/04.jpg'),
  neihu_room_02_05: require('./neihu_room/02/05.jpg'),

  neihu_room_03_01: require('./neihu_room/03/01.jpg'),
  neihu_room_03_02: require('./neihu_room/03/02.jpg'),
  neihu_room_03_03: require('./neihu_room/03/03.jpg'),
  neihu_room_03_04: require('./neihu_room/03/04.jpg'),


  //國泰竹教產後護理之家公共區域
  background_06_a: require('./background/06_a_background.png'),
  hsinchu_14_a_01: require('./14_a/01.png'),
  hsinchu_14_a_02: require('./14_a/02.png'),
  hsinchu_14_a_03: require('./14_a/03.png'),
  hsinchu_14_a_04: require('./14_a/04.png'),
  hsinchu_14_a_05: require('./14_a/05.png'),
  hsinchu_14_a_06: require('./14_a/06.png'),
  hsinchu_14_a_07: require('./14_a/07.png'),
  hsinchu_14_a_08: require('./14_a/08.png'),
  //國泰竹教房型介紹
  hsinchu_14_b_01: require('./14_b/01.jpg'),
  // hsinchu_14_b_02: require('./14_b/02.png'),
  // hsinchu_14_b_03: require('./14_b/03.png'),
  //國泰竹教房型介紹詳細
  hsinchu_room_01_01: require('./hsinchu_room/01/01.jpg'),
  hsinchu_room_01_02: require('./hsinchu_room/01/02.jpg'),
  hsinchu_room_01_03: require('./hsinchu_room/01/03.jpg'),
  hsinchu_room_01_04: require('./hsinchu_room/01/04.jpg'),
  hsinchu_room_01_05: require('./hsinchu_room/01/05.jpg'),
  
  // hsinchu_room_02_01: require('./hsinchu_room/02/01.png'),
  // hsinchu_room_02_02: require('./hsinchu_room/02/02.png'),
  // hsinchu_room_02_03: require('./hsinchu_room/02/03.png'),

  // hsinchu_room_03_01: require('./hsinchu_room/03/01.png'),
  // hsinchu_room_03_02: require('./hsinchu_room/03/02.png'),
  // hsinchu_room_03_03: require('./hsinchu_room/02/03.png'),
};
