import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'shards-react';
import { css } from '@emotion/core';
import ReactShadowScroll from 'react-shadow-scroll';
import { useMediaQuery } from 'react-responsive';

import images from '../../images/images';
import _ from 'lodash';

const DocListPopUp = ({
  overlayAction,
  isShowDialog = false,
  detail: { name, title, position, expertise, education, experience, imagePath, id },
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const IconTitle = (props) => (
    <div style={{ display: 'flex' }}>
      <img className="mr-1" src={props.img} height={18} width={18} style={{ marginTop: 2 }} />
      <span style={{ display: 'flex', marginBottom: 10 }}>{props?.title}</span>
    </div>
  );
  const Seperator = () => <div style={{ width: 50, marginBottom: 10, height: 1, backgroundColor: '#C5C1BA' }} />;
  const SpanItems = (props) =>
    _.map(props?.datas, (item, index) => (
      <span key={index} style={{ display: 'flex', marginBottom: 10 }}>
        {item}
      </span>
    ));
  return (
    <div css={stylesModal}>
      <Modal
        css={styles}
        centered
        open={isShowDialog}
        toggle={() => overlayAction && overlayAction()}
        backdropClassName="backdropBackground"
      >
        <ModalBody>
          <Col>
            <Row>
              <label className="modal-title mb-3">簡介</label>
              <span className="btn" onClick={() => overlayAction && overlayAction()}>
                <img className="d-table mx-auto mb-3" src={images.close} alt="close" />
              </span>
            </Row>
            <Row style={{ background: '#C5C1BA', height: 1, marginRight: 0 }} />
            <Row>
              <ReactShadowScroll
                isShadow={false}
                scrollColor="#52B54F"
                style={{
                  height: 'calc(50vh)',
                  marginBottom: 20,
                  marginTop: 20,
                  padding: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: '100%',
                }}
              >
                <Row style={{ width: '100%', flexDirection: isMobile ? 'column' : '' }}>
                  <img
                    src={`${process.env.REACT_APP_SERVER_HOST}${imagePath}`}
                    style={{
                      width: 250,
                      height: 250,
                      marginLeft: isMobile ? '' : '20px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      alignSelf: isMobile ? 'center' : '',
                    }}
                  ></img>
                  <Col style={{ marginLeft: isMobile ? '' : 30 }}>
                    <label className="modal-title mb-3">{name + ' ' + title}</label>
                    <SpanItems datas={position} />
                    <Seperator />
                    <IconTitle title={'專長'} img={images.doctor_01} />
                    <ul style={{ listStyle: 'disc', marginLeft: '-20px' }}>
                      {_.map(expertise, (con, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>
                          {con}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col>
                    {isMobile && <Seperator />}
                    <IconTitle title={'學歷'} img={images.doctor_02} />
                    <SpanItems datas={education} />
                    <Seperator />
                    <IconTitle title={'經歷'} img={images.doctor_03} />
                    <SpanItems datas={experience} />
                  </Col>
                </Row>
              </ReactShadowScroll>
            </Row>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DocListPopUp;

const stylesModal = css`
  .modal {
    z-index: 3000;
  }
`;
const styles = css`
  min-width: calc(100% - 300px);
  .modal-body {
    padding: 20px;
  }
  .element {
    width: fit-content;
    background: white;
    width: 100%;
    max-width: calc(100vw - 60px);
    margin-bottom: 40px;
  }
  .element img {
    width: 100%;
  }

  .modal-dialog {
    margin: auto;
  }
  .modal-header {
    border: none;
    font-family: PingFangTC-Medium;
    font-size: 13px;
    color: #505050;
    text-align: justify;
  }
  .modal-content {
    margin-top: 40px;
    max-height: calc(100vh - 80px);
    background: #f7f4ee;
    border-radius: 6px;
  }
  span.btn {
    position: absolute;
    top: -12px;
    right: -20px;
  }
  span.btn img {
    width: 20px;
  }
  .modal-title {
    font-family: PingFangTC-Medium;
    font-size: 24px;
    color: #5e5e5e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .create-date {
    font-family: PingFangTC-Regular;
    font-size: 14px;
    color: #5e5e5e;
    text-align: justify;
    font-weight: 400;
  }
  .share-row {
    justify-content: space-between;
  }
  .share-row img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    cursor: pointer;
  }
  .subcontainer {
    @media (max-width: 992px) {
      overflow-x: hidden;
    }
  }
`;
