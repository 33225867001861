import _ from 'lodash';
import useApi, { API_METHOD } from '../hooks/use-api';
import { NEWS, TOP_NEWS_LIST, NEWS_LIST } from './API';

const useNews = () => {
  const { FetchApi } = useApi();

  const getNews = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, NEWS_LIST, null);
    return { status, message, result };
  };

  const getTopNews = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, TOP_NEWS_LIST, null);
    return { status, message, result };
  };

  const getNewsDetail = async (id) => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, `${NEWS}${id}`, null);
    return { status, message, result };
  };

  return { getNews, getTopNews, getNewsDetail };
};

export default useNews;
