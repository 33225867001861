import React, { useContext, createContext } from 'react';
import { useImmer } from 'use-immer';

import getSidebarNavItems from '../data/sidebar-nav-items';
import getMainNavItems from '../data/main-nav-items';

const defaultState = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  mainItems: getMainNavItems(),
};

const LocalStateContext = createContext();

const useLocalState = () => {
  const [state, produce] = useContext(LocalStateContext);

  const toggleSidebar = () => {
    produce((draft) => void (draft.menuVisible = !draft.menuVisible));
  };

  const toggleSidebarDropdown = (item) => {
    produce((draft) => {
      const items = _.flatten(_.map(draft.navItems, 'items'));

      item = _.find(items, item);
      item.open = !item.open;

      _.reject(items, item).forEach((item) => (item.open = false));
    });
  };

  return { ...state, toggleSidebar, toggleSidebarDropdown };
};

const LocalStateProvider = ({ children }) => {
  const [state, produce] = useImmer({ ...defaultState });
  return <LocalStateContext.Provider value={[state, produce]}>{children}</LocalStateContext.Provider>;
};

export default useLocalState;
export { LocalStateProvider };
