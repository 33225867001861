import React from 'react';
import { Modal, ModalBody, Row, Col, Button } from 'shards-react';
import { css } from '@emotion/core';
import ReactShadowScroll from 'react-shadow-scroll';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

import images from '../../images/images';
import _ from 'lodash';
import LazyLoad from 'react-lazy-load';

const RoomPopUp = ({
  overlayAction,
  isShowDialog = false,
  detail: { title, plain, content, link, imgArr, itemArr, position },
  isShowRealView = true,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const Seperator = () => (
    <div style={{ width: '100%', marginBottom: 20, marginTop: 20, height: 1, backgroundColor: '#C5C1BA' }} />
  );
  const SpanItems = (props) =>
    _.map(props?.datas, (item, index) => (
      <span key={index} style={{ display: 'flex', marginBottom: 10 }}>
        {item}
      </span>
    ));

  const SliderArrow = ({ style, currentSlide, slideCount, isPrev, ...props }) => {
    return <img {...props} style={{ height: '38px' }} src={isPrev ? images.prev_g_01 : images.next_g_01} alt="next" />;
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    prevArrow: <SliderArrow isPrev={true} />,
    nextArrow: <SliderArrow />,
    lazyLoad: true,
  };
  return (
    <div css={stylesModal}>
      <Modal
        css={styles}
        centered
        open={isShowDialog}
        toggle={() => overlayAction && overlayAction()}
        backdropClassName="backdropBackground"
      >
        <ModalBody>
          <Col>
            <Row>
              <label className="modal-title mb-3">房型介紹</label>
              <span className="btn" onClick={() => overlayAction && overlayAction()}>
                <img className="d-table mx-auto mb-3" src={images.close} alt="close" />
              </span>
            </Row>
            <Row style={{ background: '#C5C1BA', height: 1, marginRight: 0 }} />
            <Row>
              <ReactShadowScroll
                isShadow={false}
                scrollColor="#52B54F"
                style={{
                  height: 'calc(60vh)',
                  marginBottom: 20,
                  marginTop: 20,
                  padding: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: '100%',
                }}
              >
                <Row
                  style={{
                    width: isMobile ? '100%' : 'calc(100% - 50px)',
                    marginLeft: isMobile ? 5 : 50,
                    flexDirection: isMobile ? 'column' : '',
                  }}
                >
                  <Slider
                    {...sliderSettings}
                    style={{
                      // height: 'calc(60vh - 40px)',
                      width: isMobile ? '94%' : '60%', //calc(100% - 300px)',
                    }}
                  >
                    {_.map(imgArr, (data, index) => (
                      <div key={index}>
                        <LazyLoad>
                          <img
                            src={data}
                            style={{ height: isMobile ? '30vh' : '50vh', width: '100%', objectFit: 'cover', borderRadius: 6 }}
                          />
                        </LazyLoad>
                      </div>
                    ))}
                  </Slider>
                  <Col
                    style={{ marginLeft: isMobile ? '' : 60, marginTop: isMobile ? 15 + Math.ceil(imgArr.length / 9) * 15 : '' }}
                  >
                    <label className="modal-title mb-3">{title}</label>
                    <SpanItems datas={position} />

                    <div style={{ display: 'flex' }}>
                      <img className="mr-1" src={images.square_feet} height={22} width={22} />
                      <span style={{ display: 'flex', marginBottom: 10 }}>{plain}</span>
                    </div>
                    <span
                      style={{
                        fontSize: 16,
                        color: '#5E5E5E',
                        fontWeight: 400,
                      }}
                    >
                      {content}
                    </span>
                    <Seperator />
                    <ul style={{ listStyle: 'disc', marginLeft: '-20px' }}>
                      {_.map(itemArr, (con, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>
                          {con}
                        </li>
                      ))}
                    </ul>
                    {!!link && isShowRealView && (
                    <Button
                      theme="success"
                      className="my-1"
                      style={{
                        maxHeight: 42,
                        width: 100,
                        fontSize: 16,
                        backgroundColor: 'transparent',
                        color: 'rgba(82,181,79,1)',
                        marginLeft: isMobile ? 'calc(50% - 55px)' : '',
                      }}
                      onClick={() => link && window.open(link, '_blank')}
                    >
                      環景預覽
                    </Button>)}
                  </Col>
                </Row>
              </ReactShadowScroll>
            </Row>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RoomPopUp;

const stylesModal = css`
  .modal {
    z-index: 3000;
  }
`;
const styles = (isMobile) => css`
  min-width: calc(100% - 300px);
  .modal-body {
    padding: 20px;
  }
  .modal-dialog {
    margin: auto;
  }
  .modal-header {
    border: none;
    font-family: PingFangTC-Medium;
    font-size: 13px;
    color: #505050;
    text-align: justify;
  }
  .modal-content {
    margin-top: 40px;
    max-height: calc(100vh - 80px);
    background: #f7f4ee;
    border-radius: 6px;
  }
  span.btn {
    position: absolute;
    top: -12px;
    right: -20px;
  }
  span.btn img {
    width: 20px;
  }
  .modal-title {
    font-family: PingFangTC-Medium;
    font-size: 24px;
    color: #5e5e5e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .create-date {
    font-family: PingFangTC-Regular;
    font-size: 14px;
    color: #5e5e5e;
    text-align: justify;
    font-weight: 400;
  }
  .subcontainer {
    @media (max-width: 992px) {
      overflow-x: hidden;
    }
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: rgba(82, 181, 79, 1);
  }
  .slick-dots li button:before {
    opacity: 1;
    color: rgba(221, 217, 211, 1);
  }
  .slick-prev {
    left: -35px;
    z-index: 1000;
  }
  .slick-next {
    right: -35px;
    z-index: 1000;
  }
  .slick-dots {
    bottom: auto;
  }
`;
