import React, { useState, useEffect, useContext, createContext } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';

import useLocalStorage from './use-localstorage';

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useAuthProvider = () => {
  const [token, setToken] = useLocalStorage('token', null);
  const [user, setUser] = useState(token);

  const signUp = () => {};

  const signIn = () => {};

  const signOut = () => {};

  useEffect(() => {}, []);

  return { user, signUp, signIn, signOut };
};

export default useAuth;
export { AuthProvider };
