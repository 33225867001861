import _ from 'lodash';
import useApi, { API_METHOD } from '../hooks/use-api';
import { MEDICAL_TEAM_LIST, MEDICAL_TEAM_DETAIL } from './API';

const useMedicalTeam = () => {
  const { FetchApi } = useApi();

  const getMedicalTeamList = async () => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, MEDICAL_TEAM_LIST, null);
    return { status, message, result };
  };

  const getMedicalTeamDetail = async (id) => {
    const { status, message, result } = await FetchApi(API_METHOD.GET, `${MEDICAL_TEAM_DETAIL}${id}`, null);
    return { status, message, result };
  };

  return {
    getMedicalTeamList,
    getMedicalTeamDetail,
  };
};

export default useMedicalTeam;
