import React, { useState, useEffect, useRef } from 'react';
import { FormTextarea, FormFeedback } from 'shards-react';

const CustomFormTextarea = ({ innerRef, onChange, errorMessage, ...attrs }) => {
  const defaultRef = useRef();
  const [text, setText] = useState('');

  useEffect(() => {
    innerRef && innerRef(defaultRef.current);
    setText(defaultRef.current.value);
  }, []);

  const handleTextChange = (e) => {
    onChange(e);
    setText(e.target.value);
  };

  return (
    <div className="p-0 d-flex flex-column" style={{ height: 'calc(100% - 25px)' }}>
      <FormTextarea
        {...attrs}
        innerRef={defaultRef}
        onChange={handleTextChange}
        style={{ backgroundColor: '#F7F4EE', height: '100%' }}
      />
      {errorMessage && (
        <FormFeedback className="font-weight-light position-absolute fixed-bottom" valid={!attrs.invalid}>
          {errorMessage}
        </FormFeedback>
      )}
      {/* <span className="align-self-end">{`${text.length} / ${attrs.maxLength}`}</span> */}
    </div>
  );
};

CustomFormTextarea.defaultProps = {
  onChange: () => {},
};

export default CustomFormTextarea;
