import React, { useContext, createContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import axios from 'axios';
// import FileDownload from "js-file-download";
import Dialog from '../components/common/Dialog';
// import useAuth from "./use-auth";
// import moment from "moment";

const BASE_URL = `${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_ENDPOINT}`;
const APIKit = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

export const API_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

// APIKit.interceptors.request.use((config) => {
//   config.headers.Authorization = `Bearer ${!!token ? token : ""}`;
//   return config;
// });

const ApiContext = createContext();

// const authMessage = "登入憑證已失效，請重新登入";
const defaultFailedMessage = '系統異常，請稍後再試';

const useApi = () => useContext(ApiContext);

const ApiProvider = ({ children }) => {
  const api = useApiProvider();
  const {
    isShow,
    errorMessage,
    clearErrorMessage,
    // , signOut
  } = api;

  const Action = () => {
    // if (errorMessage == authMessage) signOut();
    clearErrorMessage();
  };

  return (
    <ApiContext.Provider value={api}>
      {isShow && (
        <Dialog
          title="警告"
          message={errorMessage}
          isShowDialog={errorMessage != null}
          overlayAction={() => Action()}
          confirmButtonProp={{
            action: () => Action(),
          }}
          cancelButtonProp={{
            isShow: false,
          }}
        />
      )}
      {children}
    </ApiContext.Provider>
  );
};

const useApiProvider = () => {
  // const { token, checkAuth, signOut } = useAuth();
  const [state, produce] = useImmer({
    errorMessage: null,
    isShow: true,
  });

  const { errorMessage, isShow = true } = state;

  const clearErrorMessage = () => {
    produce((draft) => void (draft.errorMessage = null));
  };

  const setErrorMessage = (value) => {
    produce((draft) => void (draft.errorMessage = value));
  };

  // const setExpiredMessage = () => setErrorMessage(authMessage);

  const FetchApi = async (method, path, variables, show = true) => {
    produce((draft) => void (draft.isShow = show));
    // if (checkAuth() && !notAuth)
    //   return { status: 401, message: authMessage, result: null };
    let result = { status: 1, message: 'error', result: null };
    try {
      const { data } =
        method == API_METHOD.GET || method == API_METHOD.DELETE
          ? await APIKit[method](path, {
              // headers: { Authorization: token ? `Bearer ${token}` : "" },
            })
          : await APIKit[method](path, variables, {
              // headers: { Authorization: token ? `Bearer ${token}` : "" },
            });
      result = data;
      // if (result.status == 401) setErrorMessage(authMessage);
      // else
      // if (result.status != 1 && show) setErrorMessage(data.message);
      if (result.status != 1 && show) setErrorMessage(defaultFailedMessage);
    } catch (error) {
      let message = defaultFailedMessage;
      let statusCode = error.response?.status;

      // if (statusCode == 401) message = authMessage;

      result = { status: statusCode, message: message, result: null };
      if (show) {
        setErrorMessage(message);
      }
    } finally {
      return result;
    }
  };

  // const DownloadApi = (method, path, variables, fileName, notAuth) => {
  //   if (checkAuth() && !notAuth) return;
  //   const request = {
  //     url: `${BASE_URL}${path}`,
  //     method: method,
  //     responseType: "blob", // Important
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   if (method == API_METHOD.POST || method == API_METHOD.PUT)
  //     request.data = variables;

  //   axios(request).then((response) => {
  //     FileDownload(
  //       response.data,
  //       `${fileName}${moment().format("YYYYMMDDhhmm")}.csv`
  //     );
  //   });
  // };

  return {
    isShow,
    errorMessage,
    clearErrorMessage,
    // setExpiredMessage,
    FetchApi,
    // signOut,
    setErrorMessage,
    // DownloadApi,
  };
};

export default useApi;
export { ApiProvider };
