import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { css } from '@emotion/core';
import _ from 'lodash';
import { Button, Container, Card, CardBody, CardTitle, Row, Col, FormGroup } from 'shards-react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useMediaQuery } from 'react-responsive';
import ReactShadowScroll from 'react-shadow-scroll';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import useMedicalTeam from '../../api/medical-team-service';
import useRouter from '../../hooks/use-router';
import { DocListPopUp, ListPopUp, ListContainer } from '../../components/custom-list';
import MainFooter from '../../components/layout/MainFooter';
import LazyLoad from 'react-lazy-load';

const MedicalLineup = () => {
  const [state, produce] = useImmer({
    ...location.state,
    isFirst: true,
    loading: false,
    loading2: false,
    list: null,
    detail: null,
    tabSelect: 0,
    tabList: null
  });
  const { isFirst, list, loading, loading2, detail, tabSelect, tabList } = state;
  const {
    query: { id },
    push,
    pathname,
  } = useRouter();
  const { getMedicalTeamList, getMedicalTeamDetail } = useMedicalTeam();
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const regionList = ['台北', '內湖', '新竹', '台南'];
  const divList = ['婦產科醫師', '小兒科醫師', '專業人員'];

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!id) return;
    getDetail(id);
  }, [id]);

  useEffect(() => {
    switch (regionList[tabSelect]){
      case '台北':
        produce((draft) => { draft.tabList = list?.taipei });
        break;
      case '內湖':
        produce((draft) => { draft.tabList = list?.neihu });
        break;
      case '新竹':
        produce((draft) => { draft.tabList = list?.hsinchu });
        break;
      case '台南':
        produce((draft) => { draft.tabList = list?.tainan });
        break;
      default:
        produce((draft) => { draft.tabList = list?.taipei });
        break;
    }
    
  }, [tabSelect, list])

  const getList = async () => {
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMedicalTeamList();
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => void (draft.list = result));
    }
  };

  const getDetail = async (id) => {
    if (!!detail) return;
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMedicalTeamDetail(id);
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => void (draft.detail = result));
    }
  };

  const handleChange = (index) => produce((draft) => { draft.tabSelect = index });

  const ListDivRow = (props) => (
    <div>
      <MetaTags>
        <title>醫護陣容介紹｜國泰綜合醫院婦產科、小兒科｜國泰產後護理之家</title>
        <meta 
          name="description" 
          content="國泰產後護理之家擁有專業醫師、護理師、營養師團隊，提供醫療級身體組成分析、階段式進補菜單規劃與母嬰相關多元課程，用專業貼心守護新生兒家庭產後幸福時光。" 
        />
        <meta
          name="keywords"
          content="國泰,國泰產後,產後護理之家,產後護理,國泰產後護理之家,坐月子,月子中心,大寶 月子中心,二寶 月子中心"
        />
      </MetaTags>
      <Row className="title">
        <h4>{divList[props?.type]}</h4>
      </Row>
      <Row>
        {_.map(props?.data, (item, index) => (
          <FormGroup key={index} className="data">
            <Row className="mx-auto">
              <Col>
                <LazyLoad height={161} offsetVertical={300}>
                  <img
                    loading="lazy"
                    src={`${process.env.REACT_APP_SERVER_HOST}${item?.imagePath}`}
                    style={{ width: 161, height: 161, objectFit: 'cover', borderRadius: '50%' }}
                  ></img>
                </LazyLoad>
                {!isMobile && <div className="mt-4" style={{ width: 161, height: 1, backgroundColor: '#C5C1BA' }} />}
              </Col>
              <Col className="my-1">
                <h5 className="my-3">{item?.name + ' ' + item?.title}</h5>
                <h6 className="my-3">{item?.position}</h6>
                <Button
                  theme="success"
                  className="my-1"
                  style={{ maxHeight: 42, width: 100, fontSize: 16 }}
                  onClick={() => push(`${pathname}/${item?.id}`)}
                >
                  查看詳細
                </Button>
                {isMobile && <div className="mt-4" style={{ width: 161, height: 1, backgroundColor: '#C5C1BA' }} />}
              </Col>
            </Row>
          </FormGroup>
        ))}
      </Row>
    </div>
  );

  const CustomBreadcrumb = (props) => (
    <Breadcrumb style={{ position: 'absolute', top: '-40px' }}>
      {_.map(props.datas, (item, index) => (
        <Breadcrumb.Item key={index} href={item?.to} active={item?.to ? false : true}>
          {item?.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );

  return (
    <Container
      fluid
      css={styles}
      style={{
        backgroundImage: `url(${images.background_06_b_02})`,
        backgroundColor: '#F1F1F1',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'absolute',
        top: 0,
        height: '100vh',
        padding: 0,
      }}
    >
      <Row noGutters className="h-100 w-100">
        <Card
          className="mx-auto card"
          style={{
            width: `${isMobile ? 'calc(100% - 20px)' : 'calc(100% - 300px)'}`,
            height: `${isMobile ? 'calc(100vh - 215px)' : 'calc(100vh - 178px)'}`,
            marginTop: 131,
            position: 'relative',
            borderRadius: 6,
            backgroundColor: 'rgba(247,244,238,1)',
          }}
        >
          <CustomBreadcrumb datas={[{ title: '首頁', to: '/' }, { title: '專業照護' }, { title: '醫護陣容' }]} />
          <CardTitle
            className="mb-0"
            style={{
              minHeight: 50,
              backgroundImage: `url(${images.top_04_a})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
            }}
          ></CardTitle>
          <CardBody className="cardbody" style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <FormGroup className="text-center mx-auto" style={{ width: '90%' }}>
              <h2>醫護陣容</h2>
              {regionList && (
                <Row className={!isMobile ? 'mt-4 mb-4 mx-auto nav' : 'mt-4 mx-auto nav'}>
                  {_.map(regionList, (item, index) => (
                    <Col
                      key={index}
                      className="px-0"
                      onClick={() => {
                        handleChange(index);
                      }}
                      style={{ cursor: 'pointer', color: tabSelect == index ? '#5E5E5E' : '#C5C1BA' }}
                    >
                      {item}
                      <div className="mt-2" style={{ height: 3, backgroundColor: tabSelect == index ? '#52B54F' : '#C5C1BA' }} />
                    </Col>
                  ))}
                </Row>
              )}
            </FormGroup>
            <ReactShadowScroll isShadow={false} scrollColor="#52B54F" styleSubcontainer={{ overflowX: 'hidden' }}>
              {(tabList?.first?.length > 0) && (
                <ListDivRow data={tabList?.first} type={0} />
              )}
              {(tabList?.second?.length > 0) && (
                <ListDivRow data={tabList?.second} type={1} />
              )}
              {(tabList?.third?.length > 0) && (
                <ListDivRow data={tabList?.third} type={2} />
              )}
            </ReactShadowScroll>
          </CardBody>
        </Card>
      </Row>
      {detail && !loading2 && (
        <DocListPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => void (draft.detail = null));
            push('/medical-lineup');
          }}
          detail={detail}
        />
      )}

      <MainFooter copyright="版權所有 © 2022 國泰台北會館產後護理之家 | 國泰內湖產後護理之家 | 國泰竹教產後護理之家 | 國泰台南產後護理之家 Copyright © Cathay Healthcare Inc., Ltd. All Rights Reserved." />
    </Container>
  );
};

export default MedicalLineup;

const styles = css`
  h2 {
    color: rgba(94, 94, 94, 1);
    font-size: 36px;
    font-weight: 500;
  }
  h4 {
    color: rgba(94, 94, 94, 1);
    font-size: 24px;
    font-weight: 500;
  }
  h5 {
    color: rgba(94, 94, 94, 1);
    font-size: 24px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  h6 {
    color: rgba(94, 94, 94, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .card {
    width: 70%;
    min-width: 315px;
    @media (max-width: 992px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      width: 80%;
    }
  }
  .cardbody {
    padding-left: 100px;
    padding-right: 100px;
    @media (max-width: 767px) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
  .title {
    margin-left: 20px;
    margin-bottom: 20px;
    @media (max-width: 1292px) {
      margin: 0;
      justify-content: center;
    }
  }
  .nav {
    width: 30%;
    @media (max-width: 1292px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .btn {
    width: 123px;
    max-height: 42px;
    font-size: 16px;
  }
  .data {
    padding-left: 20px;
    width: 50%;
    @media (max-width: 1292px) {
      padding: 0px;
      width: 100%;
      text-align: center;
    }
  }
  .data div {
    @media (max-width: 1292px) {
      margin: 0 auto;
    }
  }
  .breadcrumb {
    background-color: transparent;
  }
  .breadcrumb-item {
    color: white;
    font-size: 14px;
    font-weight: 400;
  }
  .breadcrumb-item a {
    color: white;
  }
  .breadcrumb-item.active {
    user-select: none;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    margin-top: 8px;
    border: 3px solid transparent;
    border-right: 0;
    border-left: 5px solid white;
  }
`;
