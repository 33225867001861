import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { Container } from 'shards-react';
import { css } from '@emotion/core';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';

import images from '../../images/images';

//全方位親子照護規劃
const AllRoundContainer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const AllRoundFirst = {
    titleImage: images.day_01,
    content: ['媽媽、寶寶入住護理評估。'],
  };
  const AllRoundOne = [
    {
      titleImage: images.day_02,
      content: [
        '婦產科醫師每週子宮復原／傷口檢查。',
        '小兒科醫師巡診，評估寶寶心跳、呼吸、黃疸等。',
        '鼓勵母乳哺餵、親子同室、親子共讀。',
      ],
    },
    {
      titleImage: images.day_03,
      content: ['個人身體組成量測，搭配駐館營養師一對一訪視，提供專業評估。', '自然產媽咪運動指導。'],
    },
    {
      titleImage: images.day_04,
      content: ['一對一寶寶沐浴教學。', '親子同室照護技巧指導，增進親子關係。', '剖腹產媽咪運動指導。'],
    },
  ];
  const AllRoundTwo = [
    {
      titleImage: images.day_07,
      content: ['身體組成量測。', '四階段返家電話關懷護理指導(3天、14天、30天、60天)。'],
    },
    {
      titleImage: images.day_06,
      content: ['鼓勵親子同室12-24小時。', '寶寶日誌及返家備忘錄。', '提供母乳哺育支持團體資源。'],
    },
    {
      titleImage: images.day_05,
      content: ['寶寶游泳體驗(體重>3000公克)', '母嬰居家照護指導。', '持續母乳哺育輔導。'],
    },
  ];
  return (
    <Container
      fluid
      css={styles}
      className="main-content-container p-0 home-background"
      style={{
        backgroundImage: `url(${images.background_01_e})`,
        position: 'relative',
        zIndex: 1400,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: isMobile ? 'calc(50vh - 100px)' : 'calc(50% - 220px)',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <span style={{ fontSize: '36px', color: 'white' }}>全方位親子照護規劃</span>
      </div>
      {isMobile ? (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 300px)',
            overflowX: 'scroll',
            margin: 'auto',
            marginTop: '35vh',
            position: 'relative',
          }}
        >
          <div style={{ width: `${340 * 7}px`, height: '100%', display: 'flex' }}>
            <ol style={{ marginLeft: '180px' }} className="care-ol-mobile">
              {_.map(_.concat(AllRoundFirst, AllRoundOne, _.reverse(AllRoundTwo)), (data, index) => (
                <li key={index} style={{ transform: 'translate(-50%, 0%)', width: `calc(${100 / 7}%)` }}>
                  <span className="point"></span>
                  <p className="description" style={{ marginLeft: '20px' }}>
                    <img src={data.titleImage} />
                    <ul style={{ listStyle: 'disc', marginLeft: '-20px', overflow: 'hidden' }}>
                      {_.map(data.content, (con, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>
                          {con}
                        </li>
                      ))}
                    </ul>
                  </p>
                </li>
              ))}
            </ol>
          </div>
        </div>
      ) : (
        <div className="all-round-container" style={{ position: 'relative' }}>
          <div>
            <ol className="all-round-first-ol">
              <li className="all-round-first-li">
                <span className="point"></span>
                <p className="all-round-first-p">
                  <img
                    src={AllRoundFirst.titleImage}
                    style={{
                      marginLeft: 'calc(50% - 53px)',
                    }}
                  />
                  <ul style={{ listStyle: 'disc', marginLeft: '-20px', overflow: 'hidden' }}>
                    <li>{AllRoundFirst.content}</li>
                  </ul>
                </p>
              </li>
            </ol>

            <ol>
              {_.map(AllRoundOne, (data, index) => (
                <li key={index}>
                  <span className="point"></span>
                  <p className="description">
                    <img src={data.titleImage} />
                    <ul style={{ listStyle: 'disc', marginLeft: '-20px', overflow: 'hidden' }}>
                      {_.map(data.content, (con, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>
                          {con}
                        </li>
                      ))}
                    </ul>
                  </p>
                </li>
              ))}
              <div className="semicircle"></div>
            </ol>
          </div>
          <div style={{ marginTop: '246px' }}>
            <ol>
              {_.map(AllRoundTwo, (data, index) => (
                <li key={index}>
                  <span className="point"></span>
                  <p className="description" style={{ height: 'auto' }}>
                    <img src={data.titleImage} />
                    <ul style={{ listStyle: 'disc', marginLeft: '-20px', overflow: 'hidden' }}>
                      {_.map(data.content, (con, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>
                          {con}
                        </li>
                      ))}
                    </ul>
                  </p>
                </li>
              ))}
            </ol>
            <ol className="end-ol">
              <li className="end-li"></li>
            </ol>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AllRoundContainer;

const styles = () => css`
  .all-round-container {
    height: 500px;
    width: 80%;
    margin: auto;
    margin-right: 50px;
  }
  .all-round-first-ol {
    position: absolute;
    top: 0;
    left: calc(-${100 / 6}% + ${340 / 6}px - 25px);
    width: calc(${100 / 3}% - ${340 / 3}px);
    background-color: rgba(199, 169, 127, 1);
    color: red;
    padding: 0;
  }
  .all-round-first-li {
    position: absolute;
    left: calc(-50%);
    display: inline-block;
    float: left;
    width: 100%;
    font: bold 14px arial;
    height: 50px;
  }
  .all-round-first-p {
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    padding: 10px;
    margin-top: 25px;
    position: relative;
    font-weight: normal;
    z-index: 1;
    height: 196px;
    width: calc(100%);
    color: white;
    border-radius: 6px;
  }
  /* ---- Timeline ---- */
  ol {
    position: relative;
    display: block;
    margin: 100px;
    height: 6px;
    background: rgba(199, 169, 127, 1);
    padding: 0;
  }
  .end-ol {
    position: absolute;
    top: 252px;
    left: calc(-${100 / 6}% + ${340 / 6}px - 25px);
    width: calc(${100 / 3}% - ${340 / 3}px);
    background-color: rgba(199, 169, 127, 1);
    color: red;
    z-index: 20000;
    padding: 0;
  }
  .end-ol::before {
    content: '';
    position: absolute;
    top: -12px;
    display: block;
    width: 0;
    height: 0;
    left: -10px;
    border: 15px solid transparent;
    border-left: 0;
    border-right: 24px solid white;
    border-radius: 5px;
  }
  .end-li {
    position: absolute;
    left: calc(-50%);
    display: inline-block;
    float: left;
    width: 100%;
    font: bold 14px arial;
    height: 50px;
  }
  .care-ol-mobile::after {
    content: '';
    position: absolute;
    top: -12px;
    display: block;
    width: 0;
    height: 0;
    right: -10px;
    border: 15px solid transparent;
    border-right: 0;
    border-left: 24px solid white;
    border-radius: 5px;
  }

  /* ---- Timeline elements ---- */
  ol > li {
    position: relative;
    display: inline-block;
    float: left;
    width: calc(${100 / 3}%);
    font: bold 14px arial;
    height: 50px;
  }
  li .diplome {
    position: absolute;
    top: -47px;
    left: 36%;
    color: #000000;
  }
  li .point {
    content: '';
    top: -13px;
    left: 43%;
    display: block;
    width: 30px;
    height: 30px;
    border: 7px solid white;
    border-radius: 15px;
    background: rgba(199, 169, 127, 1);
    position: absolute;
  }
  li img {
    height: 26px;
    inline-size: min-content;
    align-self: center;
    margin-bottom: 10px;
  }
  li .description {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    padding: 10px;
    margin-left: 30px;
    margin-top: 25px;
    position: relative;
    font-weight: normal;
    z-index: 1;
    height: 196px;
    width: calc(100% - 50px);
    color: white;
    border-radius: 6px;
  }
  .semicircle {
    margin-left: auto;
    margin-right: -120px;
    width: 129px;
    height: 258px;
    border-bottom-right-radius: 129px;
    border-top-right-radius: 129px;
    border: 6px solid rgba(199, 169, 127, 1);
    border-bottom: 10;
    border-left: 0;
  }
`;
