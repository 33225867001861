import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer } from '../../components/custom-list/index';

const Baby = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const BabyDatas = [
    {
      image: images.baby_10_01,
      title: '環境感染管控',
      items: [
        '嚴格執行感染管控，門禁刷卡限制進出人員。',
        '衛生主管機關定期評鑑監督。',
        '毎週2次公共區域紫外線消毒，維護安心環境。',
        '國泰台北會館產後護理之家樓層限定，寶寶套房設計，專屬獨立浴盆、尿布台、沐浴組，給予寶寶最乾淨、安全和舒適的環境。',
        '大面積透明窗，清楚觀看寶寶成長、護理師照顧情形。',
      ],
    },
    {
      image: images.baby_10_04,
      title: '專業醫護',
      items: [
        '護理師：嬰兒=1：5，零保姆，由專業證照護理師 24小時，輪班照護。',
        '每週2次小兒科醫師巡診，一對一了解寶寶健康。',
        '與專業醫療單位合作，醫療後送制度嚴謹完善。',
      ],
    },
    {
      image: images.baby_10_11,
      title: '嬰兒泳池',
      items: [
        '專職護理師在旁照護，讓寶寶安全地暢游在溫水泳池中，重溫在母親體內的親密感。',
        '落地透明玻璃窗，讓媽咪、親友在大廳也可觀賞寶寶游泳的可愛姿態。',
      ],
    },
    {
      image: images.baby_10_06,
      title: '寶寶SPA按摩',
      items: [
        '觸覺是寶寶的第一身體語言，透過嬰兒按摩，幫助寶寶腦部與神經系統發育，增強肌肉骨骼、營養吸收和免疫系統。',
        '按摩時一邊說出寶寶身體部位名稱，增加寶寶安全感。',
        '藉由不同部位的按摩過程，觀察寶寶的身體發展情況。',
      ],
    },
    {
      image: images.baby_10_07,
      title: '寶寶視訊',
      items: ['寶寶一對一視訊，透過智慧型手機、房內電視，輸入專屬帳號密碼，即時觀看寶寶影像。'],
    },
  ];
  const BabyAppData = {
    title: '國泰寶寶APP',
    items: [
      '專業團隊與您一同記錄寶寶成長。',
      '透過照片上傳留存寶寶生活點滴。',
      '視覺化數據管理，每日追蹤寶寶體重、進食、排泄情形。',
      '媽媽教室課程、衛教資訊及預防針施打提醒，隨時掌握新消息。',
      '入住期間即時查看寶寶狀態。',
    ],
  };

  const CustomImg = ({ width, imgSrc, style }) => (
    <img
      src={imgSrc}
      style={{
        marginBottom: isMobile && 15,
        width: isMobile ? 'calc(100% - 20px)' : width,
        marginLeft: isMobile ? 25 : 15,
        height: isMobile ? 260 : 'fit-content',
        objectFit: isMobile ? 'cover' : '',
        borderRadius: 6,
        ...style,
      }}
    />
  );

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '專業照護' }, { title: '寶寶呵護' }]}
      titleBackground={images.top_10}
      background={images.background_10}
      title={'寶寶呵護'}
      // titleBtn={['環境感染管控', '專業醫護', '嬰兒泳池', '寶寶SPA按摩', '寶寶視訊', '國泰寶寶APP']}
    >
      <MetaTags>
        <title>寶寶呵護｜零保姆專業醫護、寶寶App一對一視訊｜國泰產後護理之家</title>
        <meta name="description" content="專業證照護理師 24小時輪班照護，嚴格執行感染管控，限制進出人員。App一對一視訊，還能追蹤寶寶體重、進食、排泄情形，與媽媽一同溫柔呵護寶寶成長。" />
        <meta
          name="keywords"
          content="國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心,月子中心 醫師巡診,大寶 月子中心,二寶 月子中心,寶寶視訊,月子中心 寶寶視訊,月子中心 嬰兒室,產後護理之家 嬰兒室"
        />
      </MetaTags>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {_.map(BabyDatas, (data, index) => (
            <div key={index}>
              <Row
                id={`item${index}`}
                className={index == 0 ? "mb-4" : "my-4"}
                style={{ width: '100%', flex: 1, flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }}
              >
                <CustomImg imgSrc={data.image} width={index == 0 ? '30%' : '65%'} />
                <Col className="ml-2" style={{ alignSelf: index == BabyDatas.length - 1 ? '' : 'center' }}>
                  <h4>{data.title}</h4>
                  <ul style={{ marginLeft: -15 }}>
                    {_.map(data.items, (item, index) => (
                      <li
                        key={index}
                        style={{
                          fontSize: 16,
                          color: '#5E5E5E',
                          textAlign: 'justify',
                          fontWeight: 400,
                          marginBottom: 15,
                          listStyleImage: `url(${images.tree_icon})`,
                        }}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
              {index == 0 && (
                <Row style={{ width: '100%', flex: 1 }}>
                  <CustomImg
                    imgSrc={images.baby_10_02}
                    width={`${200 / 3}%`}
                    style={{ paddingLeft: isMobile ? '' : 15, marginLeft: isMobile ? 25 : '' }}
                  />
                  <CustomImg
                    imgSrc={images.baby_10_03}
                    width={`${100 / 3}%`}
                    style={{ paddingLeft: isMobile ? '' : 30, marginLeft: isMobile ? 25 : '', height: isMobile ? 260 : '' }}
                  />
                </Row>
              )}
              {index == BabyDatas.length - 1 && (
                <Row id={`item${BabyDatas.length}`} style={{ width: '100%', flex: 1, position: 'relative' }}>
                  <Col className="ml-2" style={{ alignSelf: 'center' }}>
                    <h4>{BabyAppData.title}</h4>
                    <ul style={{ marginLeft: -15 }}>
                      {_.map(BabyAppData.items, (item, index) => (
                        <li
                          key={index}
                          style={{
                            fontSize: 16,
                            color: '#5E5E5E',
                            textAlign: 'justify',
                            fontWeight: 400,
                            marginBottom: 15,
                            listStyleImage: `url(${images.tree_icon})`,
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  {!isMobile && (
                    <img
                      src={images.baby_10_09}
                      style={{ width: '13%', alignSelf: 'center', height: 'fit-content', paddingLeft: 15, marginRight: 35 }}
                    />
                  )}
                  {!isMobile && (
                    <img
                      src={images.baby_10_10}
                      style={{ width: '13%', alignSelf: 'center', height: 'fit-content', paddingLeft: 15 }}
                    />
                  )}
                  {!isMobile && <Col style={{ width: '33%' }}></Col>}
                  {!isMobile && (
                    <img src={images.baby_10_08} style={{ position: 'absolute', bottom: 0, right: 0, width: '33%' }} />
                  )}

                  {isMobile && (
                    <Row className="mb-4" style={{ width: '100%', marginLeft: 30 }}>
                      <img
                        src={images.baby_10_09}
                        style={{ width: '45%', alignSelf: 'center', height: 'fit-content', paddingLeft: 15 }}
                      />
                      <img
                        src={images.baby_10_10}
                        style={{ width: '45%', alignSelf: 'center', height: 'fit-content', paddingLeft: 15 }}
                      />
                    </Row>
                  )}
                  {isMobile && <img src={images.baby_10_08} style={{ width: '100%' }} />}
                </Row>
              )}
              {index != BabyDatas.length - 1 && (
                <Row
                  style={{
                    marginTop: 19,
                    background: '#C5C1BA',
                    height: 1,
                    marginRight: 0,
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </TopScrollContainer>
  );
};

export default Baby;
