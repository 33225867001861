import React from 'react';
import { Row, Col } from 'shards-react';
import useRouter from '../../hooks/use-router';
import LazyLoad from 'react-lazy-load';

const MobileCustomListItem = ({ title, image, intro, id, tab }) => {
  const { push, pathname } = useRouter();
  return (
    <Row>
      <Col className="custom-list-item" onClick={() => push(`${pathname}/${id}`)}>
        <Row>
        <LazyLoad height={200} offsetHorizontal={800}>
          <img src={`${process.env.REACT_APP_SERVER_HOST}${image}`} />
        </LazyLoad>
          {tab && <div className="item-tab">{tab}</div>}
        </Row>
        <Row className="custom-list-item-col">
          <div>
            <label>{title}</label>
            <p>{intro}</p>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default MobileCustomListItem;
